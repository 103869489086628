import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import '../page/pageLayout.css';
import { useDispatch } from 'react-redux';
import {
    createViewAggregated,
    updateViewAggregated,
} from '../../store/ApiSlice/canvasSlice';
import { toast } from 'react-toastify';
import Loader from '../loader/Loader';
import { getMeasureList } from '../../store/ApiSlice/measureSlice';
import { AgGridReact } from "@ag-grid-community/react";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ClipboardModule,
    RangeSelectionModule,
]);

const MyGridComponent = ({
    loader,
    setLoader,
    finalData,
    aggregatedViewData,
    gridRef
}) => {
    const { dropped, filterDetail, dimensionFilteredData } = useSelector((state) => state.canvas)
    const { pending } = useSelector((state) => state.widget)
    const viewData = finalData?.widgetUID?.widgetSubType === "Measure Data" ? aggregatedViewData : dimensionFilteredData?.data
    const dispatch = useDispatch();
    const findFilterId = filterDetail?.find((item) => item?._id === finalData?.widgetUID?.worksheet?.filter)
    const [rowData, setRowData] = useState();
    const [editViewData, setEditViewData] = useState(false)
    const attributeNames = finalData?.widgetUID?.worksheet?.attributes?.length > 0
        ? finalData.widgetUID.worksheet.attributes.map(item => item.attributeName)
        : [];

    const periodValues =
        finalData?.widgetUID?.worksheet?.timePeriods?.periodValues?.length > 0
            ? finalData.widgetUID.worksheet.timePeriods.periodValues
            : [];

    const mergeMeasureData = [...attributeNames, ...periodValues];

    const formatTimePeriods = (timePeriods) => {
        return finalData?.widgetUID?.widgetSubType === "Measure Data" && `Time Periods (${timePeriods})`;
    };
    const timePeriodsFormatted = formatTimePeriods(finalData?.widgetUID?.worksheet?.timePeriods?.periodType);

    useEffect(() => {
        dispatch(getMeasureList())
    }, []);

    useEffect(() => {
        const dataWidget = pending?.length > 0 && pending?.filter(item => item.field == timePeriodsFormatted);

        const dataWithIds = (viewData?.length > 0 ? viewData : []).map((item, index) => ({
            ...item,
        }));
        const reorderData = [
            ...(finalData?.widgetUID?.cycleID?.length > 0 ? ["Cycle ID"] : []),
            ...attributeNames,
            ...(finalData?.widgetUID?.widgetSubType === 'Measure Data' ? ["Measure Name"] : []),
            "Scenario",
            ...periodValues
        ]
        function reorderObjectKeys(obj, order) {
            const sorted = {};
            order.forEach(key => {
                if (obj.hasOwnProperty(key)) {
                    sorted[key] = obj[key];
                }
            });
            Object.keys(obj).forEach(key => {
                if (!sorted.hasOwnProperty(key)) {
                    sorted[key] = obj[key];
                }
            });

            return sorted;
        }

        const data = dataWithIds?.map(item => reorderObjectKeys(item, reorderData));
        const columnData = dataWidget?.length > 0 && data?.map((item, index) => {
            const itemKeys = Object.keys(item);
            const timePeriods = finalData?.widgetUID?.worksheet?.timePeriods?.periodValues || [];
            const filteredKeys = itemKeys.filter(key => !timePeriods.includes(key));


            const newObj = filteredKeys.reduce((acc, key) => {
                acc[key] = item[key];
                return acc;
            }, {});
            newObj["Time Period"] = timePeriods[index];
            return newObj
        });

        let tableData = []
        if (dataWidget?.length > 0) {
            tableData = [...columnData]
        } else {
            tableData = [...data]
        }

        setRowData(tableData)
    }, [viewData, aggregatedViewData, dimensionFilteredData?.data])

    const uniqueFields =
        rowData?.length > 0 &&
        rowData?.reduce((fields, obj) => {
            Object.keys(obj)?.forEach((key) => {
                if (!fields?.includes(key)) {
                    fields?.push(key);
                }
            });
            return fields;
        }, []);

    const measureNameColumn = {
        headerName: 'Measure Name',
        field: 'Measure Name',
    };

    const timePeriodColumn = {
        headerName: 'Time Period',
        field: 'Time Period',
    };

    const combinedArray = [
        ...(finalData?.widgetUID?.widgetSubType === 'Measure Data'
            ? [measureNameColumn]
            : []),
        {
            headerName: 'Scenario',
            field: 'Scenario',
        },
        ...(Array.isArray(mergeMeasureData) && mergeMeasureData.length > 0
            ? mergeMeasureData.map((item) => ({
                headerName: item,
                field: item,
            }))
            : []),
    ];

    const otherColumns =
        uniqueFields?.length > 0 &&
        uniqueFields
            .filter((key) => key !== 'Aggregation Mode')
            .filter((key) => key !== '_id')
            ?.map((key) => ({
                headerName: key,
                field: key,
            }));


    const columnDefs = [];

    if (otherColumns?.length > 0) {

        columnDefs.push(otherColumns[0]);

        columnDefs.push(...otherColumns.slice(1));
    }

    const mergeArrays = (pending) => {


        const droppedFields = pending?.length > 0 ? pending.map(col => col.field) : [];
        const droppedPeriodFields = pending?.length > 0 ? pending.filter(col => col.field == timePeriodsFormatted).map(col => col.field) : [];

        let newColumnDefs = (columnDefs?.length > 0 ? columnDefs : combinedArray)?.filter(col => !droppedFields.includes(col.field));
        // if (finalData?.widgetUID?.widgetType === "Worksheet") {
        if (finalData?.widgetUID?.widgetSubType === "Measure Data" && pending?.length > 0 && droppedPeriodFields?.length > 0) {
            let newData = newColumnDefs?.filter((item) => item?.headerName !== "Time Period")
            let data = pending.map((item) => ({ ...item }));
            let timePeriodIndex = data?.findIndex((item) => item.field == timePeriodsFormatted)
            if (timePeriodIndex !== -1) {
                data[timePeriodIndex] = {
                    ...data[timePeriodIndex],
                    headerName: finalData?.widgetUID?.worksheet?.timePeriods?.periodType,
                    field: "Time Period",
                    minWidth: 60
                };
            }
            newColumnDefs = newData
            return [...data, ...newColumnDefs];
        }
        else {
            return [...pending, ...newColumnDefs]
        }
        // } 
        // else if (finalData?.widgetUID?.widgetType === "Chart") {
        //     return [...pending, ...newColumnDefs]
        // }

    };

    const mergedArray = mergeArrays(pending);

    const mergedData = [...mergedArray];

    const uniqueArray =
        mergedData?.length > 0 &&
        mergedData?.filter(
            (item, index, self) =>
                index ===
                self.findIndex(
                    (t) => t.field === item.field && t.headerName === item.headerName
                )
        );

    const defaultColDef = {
        flex: 1,
        minWidth: 70,
        editable: true,
        resizable: true,
    };

    const onCellValueChanged = (params) => {
        const { data, newValue, oldValue, colDef, node } = params;
        if (newValue !== oldValue) {
            const updatedRowData = rowData?.map((row) => {
                if (row.id === data.id) {
                    return { ...row, [colDef.field]: newValue };
                }
                return row;
            });
            setRowData(updatedRowData);
        }
    };

    const apiRef = useRef({
        grid: undefined,
        column: undefined,
    });

    const onGridReady = (params) => {
        apiRef.current.grid = params.api;
        apiRef.current.column = params.columnApi;
    };

    const handleView = async () => {
        setLoader(true);
        const payload = {
            measures: finalData?.widgetUID?.worksheet?.measures,
            timeFields: finalData?.widgetUID?.worksheet?.timePeriods,
            aggregationFields: finalData?.widgetUID?.worksheet?.attributes?.map(
                (item) => item?.attributeName
            ),
            scenario: ['Baseline'],
            worksheetUID: finalData?.widgetUID?.worksheet?._id,
            ...(findFilterId?.attributes?.length > 0 && {
                additionalFilters: findFilterId?.attributes?.map((item) => ({
                    attribute: item?.attributeUID?.name,
                    operator: item?.operator,
                    values: item?.values,
                })),
            }),
        };
        const result = await dispatch(createViewAggregated({ payload }));
        if (result?.meta?.requestStatus == 'fulfilled') {
            setLoader(false);
        } else if (result?.meta?.requestStatus === 'rejected') {
            toast.error(result?.error?.message);
            setLoader(false);
        }
    };

    const handleClick = async () => {
        setLoader(true);
        const payload = {
            measures: finalData?.widgetUID?.worksheet?.measures,
            timeFields: finalData?.widgetUID?.worksheet?.timePeriods,
            aggregationFields: finalData?.widgetUID?.worksheet?.attributes?.map(
                (item) => item?.attributeName
            ),
            scenario: ['Baseline'],
            worksheetUID: finalData?.widgetUID?.worksheet?._id,
            ...(findFilterId?.attributes?.length > 0 && {
                additionalFilters: findFilterId?.attributes?.map((item) => ({
                    attribute: item?.attributeUID?.name,
                    operator: item?.operator,
                    values: item?.values,
                })),
            }),
            documentArray: rowData,
        };
        const result = await dispatch(
            updateViewAggregated({ payload, editViewData })
        );
        if (result?.meta?.requestStatus == 'fulfilled') {
            setLoader(false);
            setEditViewData(false);
            handleView();
        } else if (result?.meta?.requestStatus === 'rejected') {
            toast.error(result?.error?.message);
            setLoader(false);
        }
    };

    const containerStyle = useMemo(() => ({ width: '100%', height: '100%', color: "black" }), []);
    const selection = useMemo(() => {
        return { mode: "cell" };
    }, []);
    return (
        <>
            <div style={containerStyle}>
                <div
                    className={'ag-theme-quartz'}
                    style={{ height: '400px', borderCollapse: 'collapse', zIndex: '9999999999999', fontSize: "12px" }}
                >
                    {loader ? (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Loader />
                        </div>
                    ) : (

                        // <AgGridReact
                        //     ref={gridRef}
                        //     rowData={rowData || []}
                        //     columnDefs={
                        //         uniqueArray?.length > 0 ? uniqueArray : combinedArray
                        //     }
                        //     // suppressColumnMove={true}
                        //     // suppressRowClickSelection
                        //     // enterNavigatesVertically={true}
                        //     // enterNavigatesVerticallyAfterEdit={true}
                        //     onGridReady={onGridReady}
                        //     defaultColDef={defaultColDef}
                        //     // onCellValueChanged={onCellValueChanged}
                        //     ensureDomOrder={true}
                        //     enableCellTextSelection={true}

                        // />
                        <AgGridReact
                            ref={gridRef}
                            rowData={rowData || []}
                            columnDefs={uniqueArray?.length > 0 ? uniqueArray : combinedArray}
                            onGridReady={onGridReady}
                            defaultColDef={defaultColDef}
                            selection={selection}
                            copyHeadersToClipboard={true}
                        />
                    )
                    }
                </div>
            </div>
        </>
    );
};

export default MyGridComponent;
