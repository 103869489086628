import { IconButton, Menu, MenuItem } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import { MdMoreVert } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { getRole } from '../../store/ApiSlice/roleSlice';
import { useSelector } from 'react-redux';

const RolematerialList = ({
    handleTableRowSelect,
    setUpdatedId,
    handleCreateUpdate,
    setModalDeleteOpen
}) => {
    const { roleData } = useSelector(state => state.role);

    const [openedMenus, setOpenedMenus] = useState({});
    const dispatch = useDispatch()

    const handleClick = (rowId) => (event) => {
        setOpenedMenus((prevMenus) => ({
            ...prevMenus,
            [rowId]: event.currentTarget,
        }));
    };

    const handleClose = (id) => {
        setOpenedMenus(prevState => ({
            ...prevState,
            [id]: null
        }));
    };

    useEffect(() => {
        dispatch(getRole())
    }, [])

    const columns = useMemo(() => [
        {
            accessorKey: "enterpriseName",
            enableColumnOrdering: false,
            header: "Enterprise",
            enableSorting: false,
            Cell: ({ row }) => (
                <>
                    <p>{row?.original?.enterpriseUID?.enterpriseName}</p>
                </>
            )
        },
        {
            accessorKey: "roleName",
            enableColumnOrdering: false,
            header: "Role Name",
            enableSorting: false,
            Cell: ({ row }) => (
                <>
                    <p>{row?.original?.roleName}</p>
                </>
            )
        },
        {
            accessorKey: "notes",
            enableColumnOrdering: false,
            header: "Notes",
            enableSorting: false,
            Cell: ({ row }) => (
                <>
                    <p>{row?.original?.notes ? row?.original?.notes : "-"}</p>
                </>
            )
        },
        {
            accessorKey: "view",
            header: "",
            enableSorting: false,
            enableColumnOrdering: false,
            Cell: ({ row }) => {
                return (
                    <>
                        <IconButton onClick={handleClick(row.original._id)} size="small">
                            <MdMoreVert />
                        </IconButton>
                        <Menu
                            anchorEl={openedMenus[row.original._id]}
                            open={Boolean(openedMenus[row.original._id])}
                            onClose={() => { handleClose(row.original._id) }}
                        >

                            <MenuItem
                                onClick={() => {
                                    handleClose(row.original._id);
                                    handleCreateUpdate(true, row?.original?._id);
                                }}
                                sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                            >
                                Edit Role
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    handleClose(row.original._id);
                                    setUpdatedId(row.original._id);
                                    setModalDeleteOpen(true);
                                }}
                                sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                            >
                                Delete Role
                            </MenuItem>
                        </Menu>
                    </>
                )
            }
        }
    ])

    const table = useMaterialReactTable({
        columns: columns.filter(Boolean),
        data: roleData || [],
        enableColumnOrdering: true,
        enableSelectAll: false,
        enableRowSelection: false,
        muiSelectCheckboxProps: { color: "secondary" },
        positionToolbarAlertBanner: "head-overlay",
        enableFullScreenToggle: false,
        enableColumnPinning: true,
        muiTableHeadCellProps: {
            sx: {
                fontSize: "12px"
            },
        },
        muiTableBodyCellProps: {
            sx: {
                fontSize: "12px"
            }
        }
    });

    return <MaterialReactTable table={table} />
}

export default RolematerialList
