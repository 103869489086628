import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_BASE_URL } from "../../helper/constants";
import axios from "axios";

const userId = localStorage.getItem("accessToken");
const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };

export const getMeasureList = createAsyncThunk(
    'measure/getMeasureList',
    async () => {
        try {
            const userIds = localStorage.getItem("accessToken");
            const configs = {
                headers: {
                    Authorization: `Bearer ${JSON.parse(userIds)?.token}`, 
                },
            };
            const response = await axios.get(`${API_BASE_URL}/measure?cache=false`, configs);
            return response.data;
        } catch (error) {
            console.log("00000000000", error);
        }
    }
);

export const getMeasurePaginationList = createAsyncThunk(
    'measure/getMeasurePaginationList',
    async ({ page, itemsPerPage, search }) => {
        const userIds = localStorage.getItem("accessToken");
        const configData = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userIds)?.token}`, 
            },
        };
        const response = await axios.get(`${API_BASE_URL}/measure?itemsPerPage=${itemsPerPage}&page=${page}&search=${search === undefined ? '' : search}`, configData);
        return response.data;
    }
);

export const getMeasureListWithEnterprise = createAsyncThunk(
    'measure/getMeasureListWithEnterprise',
    async ({ formValues }) => {
        const userIds = localStorage.getItem("accessToken");
        const configData = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userIds)?.token}`, 
            },
        };
        const response = await axios.get(`${API_BASE_URL}/measure/list/${formValues?.enterpriseUID}/${formValues?.modelUID}`, configData);
        return response.data;
    }
);

export const createUpdateMeasureAsync = createAsyncThunk(
    'measure/createOrUpdate',
    async ({ payload, isEdit, selectedRows }) => {
        try {
            let result;
            if (!isEdit) {
                const baseURLCreate = `${API_BASE_URL}/measure`;
                result = await axios.post(baseURLCreate, payload, config);
            } else {
                const baseURLUpdate = `${API_BASE_URL}/measure/${selectedRows}`;
                result = await axios.put(baseURLUpdate, payload, config);
            }
            return result.data;
        } catch (error) {
            return Promise.reject(error?.response?.data?.message);
        }
    }
);

export const deleteMeasureList = createAsyncThunk('measure/deleteMeasureList',
    async ({ attriId }) => {
        try {
            const responce = await axios.delete(`${API_BASE_URL}/measure/${attriId}`, config)
            return responce
        } catch (error) {
            return Promise.reject(error?.response?.data?.error);
        }
    }
);

const measureSlice = createSlice({
    name: "measure",
    initialState: {
        loading: false,
        error: null,
        success: false,
        measureData: [],
        measureList: [],
        totalCount: "",
        measurePaginationList: [],
        measureValidateMessage: {}
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getMeasureList.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getMeasureList.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.measureData = action?.payload?.data;
            })
            .addCase(getMeasureList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getMeasurePaginationList.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getMeasurePaginationList.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.measurePaginationList = action?.payload?.data;
                state.totalCount = action?.payload?.totalDocuments
            })
            .addCase(getMeasurePaginationList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getMeasureListWithEnterprise.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getMeasureListWithEnterprise.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.measureList = action?.payload?.data?.response;
            })
            .addCase(getMeasureListWithEnterprise.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(createUpdateMeasureAsync.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(createUpdateMeasureAsync.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(createUpdateMeasureAsync.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
            .addCase(deleteMeasureList.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(deleteMeasureList.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(deleteMeasureList.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
    }
})

export default measureSlice.reducer;