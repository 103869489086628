import {
    AppBar,
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    Menu,
    MenuItem,
    Select,
    TextField,
    Toolbar,
    Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API_BASE_URL } from "../../helper/constants";
import { useDispatch } from "react-redux";
import {
    createProfile,
    getProfileData,
    getProfileMeasure,
    getProfilePrimaryTimeDimension,
} from "../../store/ApiSlice/profileSlice";
import { toast } from "react-toastify";
import { MdMoreVert } from "react-icons/md";
import CustomButton from "../common/CustomButton";
import profileFieldData from "../../profileFieldData.json"
import { CommonNumberInput } from "../commonComponent/input/CommonNumberInput";
import { CommonAutoComplete } from "../commonComponent/commonSelect/CommonSelect";

const CreateProfile = ({
    isCreate,
    setIsCreate,
    isEdit,
    setIsEdit,
    isView,
    setIsView,
    selectedRows,
}) => {
    const { profileData, profileMeasure, timeData } = useSelector(
        (state) => state.profile
    );
    const { enterpriseData } = useSelector((state) => state?.enterprise);
    const [formValues, setFormValues] = useState({});
    console.log("🚀 ~ formValues:", formValues)
    const [modalName, setModalName] = useState([]);
    const [forcastData, setForcastData] = useState([
        {
            humanforecasts: "",
        },
    ]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorMeasureEl, setAnchorMeasureEl] = useState(null);
    const [anchorOptimizationMeasureEl, setAnchorOptimizationMeasureEl] = useState(null);
    const [dataIndex, setDataIndex] = useState();
    const [driverBasedMeasure, setDriverBasedMeasure] = useState([{
        measure: "",
        categoricalVariable: false,
        impactMeasure: "",
        offset: 0
    }])
    const [optimizationMeasure, setOptimizationMeasure] = useState([])
    const deleteForCast = Boolean(anchorEl);
    const deleteDemandDriven = Boolean(anchorMeasureEl);
    const deleteOptimization = Boolean(anchorOptimizationMeasureEl);
    const dispatch = useDispatch();

    const selectedProfile = profileData?.find(
        (item) => item?._id === selectedRows
    );


    useEffect(() => {
        if (selectedProfile && isEdit) {
            setFormValues({
                name: selectedProfile?.name,
                notes: selectedProfile?.notes,
                enterpriseName: selectedProfile?.enterpriseUID?.enterpriseName,
                enterpriseUID: selectedProfile?.enterpriseUID?._id,
                modelName: selectedProfile?.modelUID?.modelName,
                modelUID: selectedProfile?.modelUID?.modalUID,
                type: selectedProfile?.type,
                timePeriod: selectedProfile?.periodicity,
                frequency: selectedProfile?.frequency,
                historyHorizon: selectedProfile?.historyHorizon,
                validationCycle: selectedProfile?.validationCycle,
                defaultErrorOrBias: selectedProfile?.defaultErrorOrBias !== null && parseFloat(selectedProfile?.defaultErrorOrBias?.replace('%', '')),
                movingAverageErrorHorizon: selectedProfile?.movingAverageErrorHorizon,
                forecastHorizon: selectedProfile?.forecastHorizon,
                baseHistoryMeasureUID: selectedProfile?.baseHistoryMeasureUID?._id,
                correctedHistoryMeasureUID:
                    selectedProfile?.correctedHistoryMeasureUID?._id,
                errorKPI: selectedProfile?.errorKPI,
                biasKPI: selectedProfile?.biasKPI,
                weightMeasureUID: selectedProfile?.weightMeasureUID?._id,
                naiveForecastMeasureUID: selectedProfile?.naiveForecastMeasureUID?._id,
                naiveForecastErrorMeasureUID:
                    selectedProfile?.naiveForecastErrorMeasureUID?._id,
                naiveForecastBiasMeasureUID:
                    selectedProfile?.naiveForecastBiasMeasureUID?._id,
                naiveForecastMovingAverageErrorUID:
                    selectedProfile?.naiveForecastMovingAverageErrorUID?._id,
                naiveForecastMovingAverageBiasUID:
                    selectedProfile?.naiveForecastMovingAverageBiasUID?._id,
                optimalForecastMeasureUID:
                    selectedProfile?.optimalForecastMeasureUID?._id,
                optimalForecastFVAMeasureUID:
                    selectedProfile?.optimalForecastFVAMeasureUID?._id,
                optimalForecastBiasFVAMeasureUID:
                    selectedProfile?.optimalForecastBiasFVAMeasureUID?._id,
                optimalForecastErrorMeasureUID:
                    selectedProfile?.optimalForecastErrorMeasureUID?._id,
                optimalForecastBiasMeasureUID:
                    selectedProfile?.optimalForecastBiasMeasureUID?._id,
                optimalForecastChurnMeasureUID:
                    selectedProfile?.optimalForecastChurnMeasureUID?._id,
                optimalForecastRankMeasureUID:
                    selectedProfile?.optimalForecastRankMeasureUID?._id,
                optimalForecastWeightMeasureUID:
                    selectedProfile?.optimalForecastWeightMeasureUID?._id,
                optimalForecastRelativeErrorMeasureUID:
                    selectedProfile?.optimalForecastRelativeErrorMeasureUID?._id,
                optimalForecastRelativeBiasMeasureUID:
                    selectedProfile?.optimalForecastRelativeBiasMeasureUID?._id,
                optimalForecastMovingAverageErrorUID:
                    selectedProfile?.optimalForecastMovingAverageErrorUID?._id,
                optimalForecastMovingAverageBiasUID:
                    selectedProfile?.optimalForecastMovingAverageBiasUID?._id,
                driverBasedForecastMeasureUID:
                    selectedProfile?.driverBasedForecastMeasureUID?._id,
                baselineDemandMeasureUID:
                    selectedProfile?.baselineDemandMeasureUID?._id,
                driverBasedForecastErrorMeasureUID:
                    selectedProfile?.driverBasedForecastErrorMeasureUID?._id,
                driverBasedForecastBiasMeasureUID:
                    selectedProfile?.driverBasedForecastBiasMeasureUID?._id,
                driverBasedForecastMovingAverageErrorMeasureUID:
                    selectedProfile?.driverBasedForecastMovingAverageErrorMeasureUID?._id,
                driverBasedForecastMovingAverageBiasMeasureUID:
                    selectedProfile?.driverBasedForecastMovingAverageBiasMeasureUID?._id,
                driverBasedForecastRelativeErrorMeasureUID:
                    selectedProfile?.driverBasedForecastRelativeErrorMeasureUID?._id,
                driverBasedForecastRelativeBiasMeasureUID:
                    selectedProfile?.driverBasedForecastRelativeBiasMeasureUID?._id,
                driverBasedForecastErrorFVAMeasureUID:
                    selectedProfile?.driverBasedForecastErrorFVAMeasureUID?._id,
                driverBasedForecastBiasFVAMeasureUID:
                    selectedProfile?.driverBasedForecastBiasFVAMeasureUID?._id,
                movingAverageErrorUpperBoundMeasureUID:
                    selectedProfile?.movingAverageErrorUpperBoundMeasureUID?._id,
                movingAverageErrorLowerBoundMeasureUID:
                    selectedProfile?.movingAverageErrorLowerBoundMeasureUID?._id,
                movingAverageBiasUpperBoundMeasureUID:
                    selectedProfile?.movingAverageBiasUpperBoundMeasureUID?._id,
                movingAverageBiasLowerBoundMeasureUID:
                    selectedProfile?.movingAverageBiasLowerBoundMeasureUID?._id,
                movingAverageChurnUpperBoundMeasureUID:
                    selectedProfile?.movingAverageChurnUpperBoundMeasureUID?._id,
                movingAverageChurnLowerBoundMeasureUID:
                    selectedProfile?.movingAverageChurnLowerBoundMeasureUID?._id,
            });
            setForcastData(
                selectedProfile?.humanForecasts?.map((item) => ({
                    humanforecasts: item?._id,
                }))
            );
            setDriverBasedMeasure(
                selectedProfile?.demandDriverMeasures?.map((item) => ({
                    measure: item?.measure?._id,
                    categoricalVariable: item?.categoricalVariable,
                    impactMeasure: item?.impactMeasure?._id,
                    offset: item?.offset
                }))
            )
            setOptimizationMeasure(selectedProfile?.optimizationObjectives?.length > 0 ? selectedProfile?.optimizationObjectives?.map((item) => ({
                objective: item?.objective,
                weight: Number(item?.weight)
            })) : [])
        }
    }, [profileData, isEdit, selectedRows, selectedProfile, enterpriseData]);

    const handleChangeProfile = (newValue, fieldName, e) => {
        if (fieldName === "enterpriseName") {
            const selectedData = enterpriseData?.length > 0 && enterpriseData?.filter(
                (data) => data?._id === newValue?._id
            );

            setFormValues({
                ...formValues,
                [fieldName]: selectedData[0]?.enterpriseName,
                [`enterpriseUID`]: newValue?._id,
            });
        } else if (fieldName === "modelName") {
            const selectedData = modalName?.length > 0 && modalName?.filter(
                (data) => data?.modalUID === newValue?.modalUID
            );

            setFormValues({
                ...formValues,
                [fieldName]: selectedData[0]?.modelName,
                [`modelUID`]: newValue?.modalUID,
            });
        } else if (
            fieldName === "baseHistoryMeasureUID" ||
            fieldName === "correctedHistoryMeasureUID" ||
            fieldName === "weightMeasureUID" ||
            fieldName === "naiveForecastMeasureUID" ||
            fieldName === "naiveForecastErrorMeasureUID" ||
            fieldName === "naiveForecastBiasMeasureUID" ||
            fieldName === "naiveForecastMovingAverageErrorUID" ||
            fieldName === "naiveForecastMovingAverageBiasUID" ||
            fieldName === "optimalForecastMeasureUID" ||
            fieldName === "optimalForecastErrorMeasureUID" ||
            fieldName === "optimalForecastFVAMeasureUID" ||
            fieldName === "optimalForecastBiasMeasureUID" ||
            fieldName === "optimalForecastChurnMeasureUID" ||
            fieldName === "optimalForecastRankMeasureUID" ||
            fieldName === "optimalForecastWeightMeasureUID" ||
            fieldName === "optimalForecastRelativeBiasMeasureUID" ||
            fieldName === "optimalForecastRelativeErrorMeasureUID" ||
            fieldName === "optimalForecastBiasFVAMeasureUID" ||
            fieldName === "optimalForecastMovingAverageErrorUID" ||
            fieldName === "optimalForecastMovingAverageBiasUID" ||
            fieldName === "driverBasedForecastMeasureUID" ||
            fieldName === "baselineDemandMeasureUID" ||
            fieldName === "driverBasedForecastErrorMeasureUID" ||
            fieldName === "driverBasedForecastBiasMeasureUID" ||
            fieldName === "driverBasedForecastMovingAverageErrorMeasureUID" ||
            fieldName === "driverBasedForecastMovingAverageBiasMeasureUID" ||
            fieldName === "driverBasedForecastRelativeErrorMeasureUID" ||
            fieldName === "driverBasedForecastRelativeBiasMeasureUID" ||
            fieldName === "driverBasedForecastErrorFVAMeasureUID" ||
            fieldName === "driverBasedForecastBiasFVAMeasureUID" ||
            fieldName === "movingAverageErrorUpperBoundMeasureUID" ||
            fieldName === "movingAverageErrorLowerBoundMeasureUID" ||
            fieldName === "movingAverageBiasUpperBoundMeasureUID" ||
            fieldName === "movingAverageBiasLowerBoundMeasureUID" ||
            fieldName === "movingAverageChurnUpperBoundMeasureUID" ||
            fieldName === "movingAverageChurnLowerBoundMeasureUID"
        ) {
            setFormValues({
                ...formValues,
                [fieldName]: newValue?._id,
            });
        }
        else if (fieldName === "timePeriod") {
            const selectedData = timeData?.timeSchemaFields?.find(
                (data) => data?._id === newValue?._id
            );

            setFormValues({
                ...formValues,
                [fieldName]: selectedData && selectedData["Period Type"],
                timePeriodId: newValue && newValue?._id,
            });
        } else if (fieldName === "frequency") {
            const selectedData = timeData?.timeSchemaFields?.find(
                (data) => data?._id === newValue?._id
            );

            setFormValues({
                ...formValues,
                [fieldName]: selectedData && selectedData["Period Type"],
                frequencyId: newValue && newValue?._id,
            });
        } else if (fieldName === "notes") {
            const { name, value } = e.target;
            const words = value?.split(" ");

            if (words.length < 10) {
                e.target.value = words?.slice(0, 100)?.join(" ");
            }
            setFormValues((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));
        } else {
            const { name, value } = e.target;
            setFormValues((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));
        }
    };

    const handleAdd = () => {
        setForcastData([...forcastData, { humanforecasts: "" }]);
    };

    const handleAddOptimizationMeasure = () => {
        setOptimizationMeasure([...optimizationMeasure, { objective: "", weight: "" }]);
    };

    const handleAddDriverMeasure = () => {
        setDriverBasedMeasure([...driverBasedMeasure, { measure: "", categoricalVariable: false, impactMeasure: "", offset: 0 }])
    }

    const handleChangeDriverBasedMeasure = (index, newValue, type, event) => {
        const updatedFormValues = [...driverBasedMeasure];
        if (type === "categoricalVariable") {
            const { checked } = event.target
            updatedFormValues[index][type] = checked;
            setDriverBasedMeasure(updatedFormValues);
        } else if (type === "offset") {
            const { value } = event.target
            updatedFormValues[index][type] = value;
            setDriverBasedMeasure(updatedFormValues);
        }
        else {
            updatedFormValues[index][type] = newValue?._id;
            setDriverBasedMeasure(updatedFormValues);
        }
    };

    const handleChangeForeCastProfile = (index, newValue, type, event) => {
        const updatedFormValues = [...forcastData];
        updatedFormValues[index][type] = newValue?._id;
        setForcastData(updatedFormValues);
    };

    const handleChangeOptimizationMeasure = (index, e) => {
        const { name, value } = e.target

        const updatedFormValues = [...optimizationMeasure];
        updatedFormValues[index][name] = value;
        setForcastData(updatedFormValues);
    };

    const handleClick = (event, index, type) => {
        if (type === "humanforecasts") {
            setAnchorEl(event.currentTarget);
            setDataIndex(index);
        } else if (type === "demandDriven") {
            setAnchorMeasureEl(event.currentTarget)
            setDataIndex(index);
        } else if (type === "optimizationObjective") {
            setAnchorOptimizationMeasureEl(event.currentTarget)
            setDataIndex(index);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleremove = (type) => {
        if (type === "humanforecasts") {
            const updatedField = [...forcastData];
            updatedField.splice(dataIndex, 1);
            setForcastData(updatedField);
            setAnchorEl(null);
        } else if (type === "demandDriven") {
            const updatedField = [...driverBasedMeasure];
            updatedField.splice(dataIndex, 1);
            setDriverBasedMeasure(updatedField);
            setAnchorMeasureEl(null);
        } else if (type === "optimizationObjective") {
            const updatedField = [...optimizationMeasure];
            updatedField.splice(dataIndex, 1);
            setOptimizationMeasure(updatedField);
            setAnchorOptimizationMeasureEl(null);
        }
    };

    const getModelList = async () => {
        try {
            const userId = localStorage.getItem("accessToken");
            const config = {
                headers: {
                    Authorization: `Bearer ${JSON.parse(userId)?.token}`,
                },
            };

            let response = await axios.get(`${API_BASE_URL}/all/getAllModel`, config);
            let filteredData = isEdit
                ? response.data?.enterpriseList?.length > 0 && response.data?.enterpriseList?.filter(
                    (item) => item.enterpriseUID?._id === formValues.enterpriseUID
                )
                : response.data?.enterpriseList;

            if (formValues.enterpriseName) {
                filteredData = filteredData?.length > 0 && filteredData?.filter(
                    (dime) => dime.enterpriseUID?._id === formValues.enterpriseUID
                );
            }
            setModalName(filteredData);
        } catch (error) {
            console.error("Error fetching model list:", error);
        }
    };

    useEffect(() => {
        if (formValues.enterpriseName) {
            getModelList();
        }
    }, [formValues.enterpriseName]);

    useEffect(() => {
        if (formValues?.enterpriseUID && formValues?.modelUID) {
            dispatch(getProfilePrimaryTimeDimension({ formValues }));
            dispatch(getProfileMeasure({ formValues }));
        }
    }, [formValues?.enterpriseUID && formValues?.modelUID]);

    const handleCancel = () => {
        setIsCreate(false);
    };

    const validation = () => {
        if (formValues?.name === "" || formValues?.name === undefined) {
            toast.error("Please enter profile name");
            return true;
        } else if (
            formValues?.enterpriseUID === "" ||
            formValues?.enterpriseUID === undefined
        ) {
            toast.error("Please select enterprise");
            return true;
        } else if (
            formValues?.modelUID === "" ||
            formValues?.modelUID === undefined
        ) {
            toast.error("Please select model");
            return true;
        } else if (formValues?.type === "" || formValues?.type === undefined) {
            toast.error("Please select type");
            return true;
        } else if (
            formValues?.timePeriod === "" ||
            formValues?.timePeriod === undefined
        ) {
            toast.error("Please select periodicity");
            return true;
        } else if (
            formValues?.frequency === "" ||
            formValues?.frequency === undefined
        ) {
            toast.error("Please select frequency");
            return true;
        } else if (
            formValues?.historyHorizon === "" ||
            formValues?.historyHorizon === undefined
        ) {
            toast.error("Please enter history horizon");
            return true;
        } else if (
            formValues?.validationCycle === "" ||
            formValues?.validationCycle === undefined
        ) {
            toast.error("Please enter validation cycle");
            return true;
        } else if (
            formValues?.forecastHorizon === "" ||
            formValues?.forecastHorizon === undefined
        ) {
            toast.error("Please enter forecast horizon");
            return true;
        } else if (
            formValues?.baseHistoryMeasureUID === "" ||
            formValues?.baseHistoryMeasureUID === undefined
        ) {
            toast.error("Please select base history");
            return true;
        } else if (
            formValues?.correctedHistoryMeasureUID === "" ||
            formValues?.correctedHistoryMeasureUID === undefined
        ) {
            toast.error("Please select corrected history");
            return true;
        } else if (
            formValues?.errorKPI === "" ||
            formValues?.errorKPI === undefined
        ) {
            toast.error("Please select error method");
            return true;
        } else if (formValues?.biasKPI === "" || formValues?.biasKPI === undefined) {
            toast.error("Please select bias method");
            return true;
        } else if (formValues?.biasKPI === "" || formValues?.biasKPI === undefined) {
            toast.error("Please select bias method");
            return true;
        } else if (
            formValues?.errorKPI?.startsWith("w") &&
            formValues?.biasKPI?.startsWith("w") &&
            (formValues?.weightMeasureUID === "" ||
                formValues?.weightMeasureUID === undefined)
        ) {
            toast.error("Please select weight measure");
            return true;
        } else if (
            formValues?.naiveForecastMeasureUID === "" ||
            formValues?.naiveForecastMeasureUID === undefined
        ) {
            toast.error("Please select naive forecast measure");
            return true;
        } else if (
            formValues?.naiveForecastErrorMeasureUID === "" ||
            formValues?.naiveForecastErrorMeasureUID === undefined
        ) {
            toast.error("Please select naive forecast error measure");
            return true;
        } else if (
            formValues?.naiveForecastBiasMeasureUID === "" ||
            formValues?.naiveForecastBiasMeasureUID === undefined
        ) {
            toast.error("Please select naive forecast bias measure");
            return true;
        }
        return false;
    };

    const handleSubmit = async () => {
        if (validation()) {
            return;
        } else {
            let humanForecasts = [];

            if (forcastData?.length > 0) {
                humanForecasts = forcastData?.length > 0 && forcastData
                    .filter(
                        (item) =>
                            item?.humanforecasts !== "" && item?.humanforecasts !== undefined
                    )
                    .map((item) => item?.humanforecasts);
            }

            const payload = {
                name: formValues?.name,
                enterpriseUID: formValues?.enterpriseUID,
                modelUID: formValues?.modelUID,
                type: formValues?.type,
                notes: formValues?.notes,
                periodicity: formValues?.timePeriod,
                frequency: formValues?.frequency,
                historyHorizon: Number(formValues?.historyHorizon),
                forecastHorizon: Number(formValues?.forecastHorizon),
                validationCycle: Number(formValues?.validationCycle),
                movingAverageErrorHorizon: Number(formValues?.movingAverageErrorHorizon),
                defaultErrorOrBias: `${formValues?.defaultErrorOrBias}%`,
                baseHistoryMeasureUID: formValues?.baseHistoryMeasureUID,
                correctedHistoryMeasureUID: formValues?.correctedHistoryMeasureUID,
                errorKPI: formValues?.errorKPI,
                biasKPI: formValues?.biasKPI,
                ...((formValues?.errorKPI === "wMAPE" ||
                    formValues?.biasKPI === "wMPE") && {
                    weightMeasureUID: formValues?.weightMeasureUID,
                }),
                naiveForecastMeasureUID: formValues?.naiveForecastMeasureUID,
                naiveForecastErrorMeasureUID: formValues?.naiveForecastErrorMeasureUID,
                naiveForecastBiasMeasureUID: formValues?.naiveForecastBiasMeasureUID,
                naiveForecastMovingAverageErrorUID: formValues?.naiveForecastMovingAverageErrorUID,
                naiveForecastMovingAverageBiasUID: formValues?.naiveForecastMovingAverageBiasUID,
                optimalForecastMeasureUID: formValues?.optimalForecastMeasureUID,
                optimalForecastErrorMeasureUID:
                    formValues?.optimalForecastErrorMeasureUID,
                optimalForecastFVAMeasureUID: formValues?.optimalForecastFVAMeasureUID,
                optimalForecastBiasFVAMeasureUID:
                    formValues?.optimalForecastBiasFVAMeasureUID,
                optimalForecastBiasMeasureUID:
                    formValues?.optimalForecastBiasMeasureUID,
                optimalForecastChurnMeasureUID:
                    formValues?.optimalForecastChurnMeasureUID,
                optimalForecastRankMeasureUID:
                    formValues?.optimalForecastRankMeasureUID,
                optimalForecastWeightMeasureUID:
                    formValues?.optimalForecastWeightMeasureUID,
                optimalForecastRelativeErrorMeasureUID:
                    formValues?.optimalForecastRelativeErrorMeasureUID,
                optimalForecastRelativeBiasMeasureUID:
                    formValues?.optimalForecastRelativeBiasMeasureUID,
                optimalForecastMovingAverageErrorUID:
                    formValues?.optimalForecastMovingAverageErrorUID,
                optimalForecastMovingAverageBiasUID:
                    formValues?.optimalForecastMovingAverageBiasUID,
                driverBasedForecastMeasureUID:
                    formValues?.driverBasedForecastMeasureUID,
                baselineDemandMeasureUID:
                    formValues?.baselineDemandMeasureUID,
                driverBasedForecastErrorMeasureUID:
                    formValues?.driverBasedForecastErrorMeasureUID,
                driverBasedForecastBiasMeasureUID:
                    formValues?.driverBasedForecastBiasMeasureUID,
                driverBasedForecastMovingAverageErrorMeasureUID:
                    formValues?.driverBasedForecastMovingAverageErrorMeasureUID,
                driverBasedForecastMovingAverageBiasMeasureUID:
                    formValues?.driverBasedForecastMovingAverageBiasMeasureUID,
                driverBasedForecastRelativeErrorMeasureUID:
                    formValues?.driverBasedForecastRelativeErrorMeasureUID,
                driverBasedForecastRelativeBiasMeasureUID:
                    formValues?.driverBasedForecastRelativeBiasMeasureUID,
                driverBasedForecastErrorFVAMeasureUID:
                    formValues?.driverBasedForecastErrorFVAMeasureUID,
                driverBasedForecastBiasFVAMeasureUID:
                    formValues?.driverBasedForecastBiasFVAMeasureUID,
                movingAverageErrorUpperBoundMeasureUID: formValues?.movingAverageErrorUpperBoundMeasureUID,
                movingAverageErrorLowerBoundMeasureUID: formValues?.movingAverageErrorLowerBoundMeasureUID,
                movingAverageBiasUpperBoundMeasureUID: formValues?.movingAverageBiasUpperBoundMeasureUID,
                movingAverageBiasLowerBoundMeasureUID: formValues?.movingAverageBiasLowerBoundMeasureUID,
                movingAverageChurnUpperBoundMeasureUID: formValues?.movingAverageChurnUpperBoundMeasureUID,
                movingAverageChurnLowerBoundMeasureUID: formValues?.movingAverageChurnLowerBoundMeasureUID,
                ...(humanForecasts?.length > 0 && { humanForecasts }),
                ...(driverBasedMeasure?.length > 0 && driverBasedMeasure?.some(item => item?.measure) && ({
                    demandDriverMeasures: driverBasedMeasure?.map((item) => ({
                        categoricalVariable: item?.categoricalVariable,
                        ...(item?.impactMeasure && ({
                            impactMeasure: item?.impactMeasure,
                        })),
                        ...(item?.offset && ({
                            offset: Number(item?.offset),
                        })),
                        ...(item?.measure && ({
                            measure: item?.measure,
                        })),

                    })),
                })),
                ...(optimizationMeasure?.length > 0 && ({
                    optimizationObjectives: optimizationMeasure?.length > 0 && optimizationMeasure?.map((item) => ({
                        objective: item?.objective,
                        weight: Number(item?.weight)
                    }))

                }))
            };
            const result = await dispatch(
                createProfile({ payload, isEdit, selectedRows })
            );
            if (result?.meta?.requestStatus === "fulfilled") {
                // toast.success(
                //     isEdit
                //         ? "Profile updated successfully"
                //         : "Profile created successfully"
                // );
                dispatch(getProfileData());
                setFormValues({});
                setForcastData([
                    {
                        humanforecasts: "",
                    },
                ]);
                setIsCreate(false);
            } else if (result?.meta?.requestStatus === "rejected") {
                toast.error(result?.error?.message);
                setIsCreate(true);
            }
        }
    };

    return (
        <div style={{ overflow: "hidden" }}>
            <AppBar position="static" sx={{ backgroundColor: "white" }}>
                <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="h6" sx={{ color: "black", fontFamily: "'Poppins', sans-serif" }}>
                        {/* {getPageHeading()} */}
                        Forecast Profile
                    </Typography>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            marginRight: "30px",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <Button
                            onClick={handleCancel}
                            title="Cancel"
                            style={{
                                padding: "6px 15px",
                                borderRadius: "2px",
                                cursor: "pointer",
                                marginRight: "10px",
                                fontFamily: "'Poppins', sans-serif"
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSubmit}
                            title="Create new dimension with selected attributes"
                            style={{
                                padding: "7px 15px",
                                borderRadius: "2px",
                                cursor: "pointer",
                                fontFamily: "'Poppins', sans-serif"
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </Toolbar>
            </AppBar>
            <Typography variant="h6" component="h6" style={{
                marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
            }}>
                Profile Overview
            </Typography>{" "}
            <Container style={{ display: "grid", paddingBottom: "20px" }}>

                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <Grid item xs={6}>
                        <TextField
                            id="name"
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="Profile Name"
                            value={formValues?.name || selectedProfile?.name}
                            onChange={(e, newValue) => {
                                handleChangeProfile(newValue, "name", e);
                            }}
                            placeholder="Enter profile name"
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="demo-simple-select-readonly"
                                labelId="Enterprise Name"
                                name="enterpriseName"
                                options={enterpriseData}
                                getOptionLabel={(option) => option.enterpriseName}
                                value={
                                    formValues?.enterpriseName
                                        ? enterpriseData.find(
                                            (option) =>
                                                option.enterpriseName === formValues.enterpriseName
                                        ) || null
                                        : null
                                }
                                onChange={(e, newValue) => {
                                    handleChangeProfile(newValue, "enterpriseName", e);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Enterprise Name"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="demo-simple-select-readonly"
                                labelId="Model Name"
                                name="modelName"
                                options={modalName}
                                getOptionLabel={(option) => option.modelName}
                                value={
                                    formValues?.modelName
                                        ? modalName.find(
                                            (option) => option.modelName === formValues.modelName
                                        ) || null
                                        : null
                                }
                                onChange={(e, newValue) => {
                                    handleChangeProfile(newValue, "modelName", e);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Model Name"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel id="type">Type</InputLabel>
                            <Select
                                labelId="type"
                                name="type"
                                id="demo-simple-select-readonly"
                                value={formValues?.type || selectedProfile?.type || ""}
                                fullWidth
                                onChange={(e, newValue) => {
                                    handleChangeProfile(newValue, "type", e);
                                }}
                                label="Type"
                                variant="outlined"

                            >
                                <MenuItem value="No Lag">No Lag</MenuItem>
                                <MenuItem value="Lag Based">Lag Based</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Container>

            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />
            <Typography variant="h6" component="h6" style={{
                marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
            }}>
                General Parameters
            </Typography>{" "}
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="demo-simple-select-readonly"
                                labelId="Periodicity"
                                name="timePeriod"
                                options={Array.isArray(timeData?.timeSchemaFields) ? timeData.timeSchemaFields : []}
                                getOptionLabel={(option) =>
                                    option?.enabled === true && option["Period Type"]
                                }
                                value={
                                    formValues?.timePeriod
                                        ? timeData?.timeSchemaFields?.find(
                                            (item) => item["Period Type"] == formValues?.timePeriod
                                        ) || null
                                        : null
                                }
                                onChange={(e, newValue) => {
                                    handleChangeProfile(newValue, "timePeriod", e);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Periodicity"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="demo-simple-select-readonly"
                                labelId="Frequency"
                                name="frequency"
                                options={Array.isArray(timeData?.timeSchemaFields) ? timeData.timeSchemaFields : []}
                                getOptionLabel={(option) =>
                                    option?.enabled === true && option["Period Type"]
                                }
                                value={
                                    formValues?.frequency
                                        ? timeData?.timeSchemaFields?.find(
                                            (item) => item["Period Type"] == formValues?.frequency
                                        ) || null
                                        : null
                                }
                                onChange={(e, newValue) => {
                                    handleChangeProfile(newValue, "frequency", e);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Frequency"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <CommonNumberInput
                            name="historyHorizon"
                            label="History Horizon"
                            value={formValues?.historyHorizon}
                            handleChangeProfile={handleChangeProfile}
                            placeholder="Enter history Horizon"
                            disabled={isView}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <CommonNumberInput
                            name="forecastHorizon"
                            label="Forecast Horizon"
                            value={formValues?.forecastHorizon}
                            handleChangeProfile={handleChangeProfile}
                            placeholder="Enter forecast Horizon"
                            disabled={isView}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <CommonAutoComplete
                                label="Base History"
                                name="baseHistoryMeasureUID"
                                value={formValues?.baseHistoryMeasureUID}
                                profileMeasure={profileMeasure}
                                handleChangeProfile={handleChangeProfile}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <CommonAutoComplete
                                label="Corrected History"
                                name="correctedHistoryMeasureUID"
                                value={formValues?.correctedHistoryMeasureUID}
                                profileMeasure={profileMeasure}
                                handleChangeProfile={handleChangeProfile}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <CommonNumberInput
                            name="validationCycle"
                            label="Validation Cycles"
                            value={formValues?.validationCycle}
                            handleChangeProfile={handleChangeProfile}
                            placeholder="Enter Validation Cycles"
                            disabled={isView}
                        />
                    </Grid>

                </Grid>
            </Container>

            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />
            <Typography variant="h6" component="h6" style={{
                marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
            }}>
                Forecast Error Measures
            </Typography>{" "}
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    {profileFieldData?.forecastError?.map((field, index) => (
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id={field?.name}>{field?.label}</InputLabel>
                                <Select
                                    labelId={field?.name}
                                    name={field?.name}
                                    id="demo-simple-select-readonly"
                                    value={formValues?.[field.name] || selectedProfile?.[field?.name] || ""}
                                    fullWidth
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, field?.name, e);
                                    }}
                                    label={field?.label}
                                    variant="outlined"
                                >
                                    {field?.option?.map((item) => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    ))}
                    {(formValues?.errorKPI === "wMAPE" ||
                        formValues?.biasKPI === "wMPE") && (
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <CommonAutoComplete
                                        label="Weight"
                                        name="weightMeasureUID"
                                        value={formValues?.weightMeasureUID}
                                        profileMeasure={profileMeasure}
                                        handleChangeProfile={handleChangeProfile}
                                    />
                                </FormControl>
                            </Grid>
                        )}

                    <Grid item xs={6}>
                        <CommonNumberInput
                            name="movingAverageErrorHorizon"
                            label="Moving Average Error Horizon"
                            value={formValues?.movingAverageErrorHorizon}
                            handleChangeProfile={handleChangeProfile}
                            placeholder="Enter error horizon"
                            disabled={isView}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <CommonNumberInput
                            name="defaultErrorOrBias"
                            label="Default Error Or Bias %"
                            value={formValues?.defaultErrorOrBias}
                            handleChangeProfile={handleChangeProfile}
                            placeholder="Enter default error or bias"
                            disabled={isView}
                        />
                    </Grid>
                </Grid>
            </Container>

            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 10px"
                }}
            >
                <Typography variant="h6" component="h6" style={{ fontFamily: "'Poppins', sans-serif" }}>
                    Optimization Objectives
                </Typography>{" "}
                <Button variant="contained" onClick={handleAddOptimizationMeasure}>
                    +
                </Button>
            </Box>
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    {optimizationMeasure?.length > 0 && optimizationMeasure?.map((item, index) => {
                        return (
                            <>
                                <Grid
                                    item
                                    xs={6}
                                    sx={{ display: "flex", alignItems: "center" }}
                                >
                                    <FormControl fullWidth>
                                        <InputLabel id="objective">objective</InputLabel>
                                        <Select
                                            labelId="objective"
                                            name="objective"
                                            id="demo-simple-select-readonly"
                                            value={item?.objective || ""}
                                            fullWidth
                                            onChange={(e) => {
                                                handleChangeOptimizationMeasure(index, e);
                                            }}
                                            label="objective"
                                            variant="outlined"
                                        >
                                            <MenuItem value="Error">Error</MenuItem>
                                            <MenuItem value="Bias">Bias</MenuItem>
                                            <MenuItem value="Churn">Churn</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    sx={{ display: "flex", alignItems: "center" }}
                                >
                                    <TextField
                                        type="number"
                                        id="weight"
                                        name="weight"
                                        variant="outlined"
                                        fullWidth
                                        label="weight"
                                        value={Number(item?.weight) || ''}
                                        onChange={(e) => {
                                            handleChangeOptimizationMeasure(index, e);
                                        }}
                                        placeholder="Enter weight"
                                    />
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={deleteOptimization ? "long-menu" : undefined}
                                        aria-expanded={deleteOptimization ? "true" : undefined}
                                        aria-haspopup="true"
                                        onClick={(e) => handleClick(e, index, "optimizationObjective")}
                                        size="small"
                                    >
                                        <MdMoreVert />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                            "aria-labelledby": "long-button",
                                        }}
                                        anchorEl={anchorOptimizationMeasureEl}
                                        open={deleteOptimization}
                                        onClose={() => { setAnchorOptimizationMeasureEl(null) }}
                                    >
                                        <MenuItem onClick={() => { handleremove("optimizationObjective") }} sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Delete</MenuItem>
                                    </Menu>
                                </Grid>
                            </>
                        )
                    })}
                </Grid>
            </Container>

            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />
            <Typography variant="h6" component="h6" style={{
                marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
            }}>
                Forecastability Bands
            </Typography>
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    {profileFieldData?.forecastabilityBand?.map((field, index) => (
                        <Grid item xs={6} key={index}>
                            <FormControl fullWidth>
                                <CommonAutoComplete
                                    label={field.label}
                                    name={field.name}
                                    value={formValues?.[field.name]}
                                    profileMeasure={profileMeasure}
                                    handleChangeProfile={handleChangeProfile}
                                />
                            </FormControl>
                        </Grid>
                    ))}
                </Grid>
            </Container>

            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />
            <Typography variant="h6" component="h6" style={{
                marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
            }}>
                Naive Forecast Measures
            </Typography>{" "}
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <CommonAutoComplete
                                label="Naive Forecast"
                                name="naiveForecastMeasureUID"
                                value={formValues?.naiveForecastMeasureUID}
                                profileMeasure={profileMeasure}
                                handleChangeProfile={handleChangeProfile}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    {profileFieldData?.naiveForecast?.map((field, index) => (
                        <Grid item xs={6} key={index}>
                            <FormControl fullWidth>
                                <CommonAutoComplete
                                    label={field.label}
                                    name={field.name}
                                    value={formValues?.[field.name]}
                                    profileMeasure={profileMeasure}
                                    handleChangeProfile={handleChangeProfile}
                                />
                            </FormControl>
                        </Grid>
                    ))}
                </Grid>
            </Container>

            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />
            <Typography variant="h6" component="h6" style={{
                marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
            }}>
                Optimal Forecast Measures
            </Typography>{" "}
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <CommonAutoComplete
                                label="Optimal Forecast"
                                name="optimalForecastMeasureUID"
                                value={formValues?.optimalForecastMeasureUID}
                                profileMeasure={profileMeasure}
                                handleChangeProfile={handleChangeProfile}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    {profileFieldData?.optimalForecast?.map((field, index) => (
                        <Grid item xs={6} key={index}>
                            <FormControl fullWidth>
                                <CommonAutoComplete
                                    label={field.label}
                                    name={field.name}
                                    value={formValues?.[field.name]}
                                    profileMeasure={profileMeasure}
                                    handleChangeProfile={handleChangeProfile}
                                />
                            </FormControl>
                        </Grid>
                    ))}
                </Grid>
            </Container>

            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 10px"
                }}
            >
                <Typography variant="h6" component="h6" style={{ fontFamily: "'Poppins', sans-serif" }}>
                    Human Forecast Measures
                </Typography>{" "}
                <Button variant="contained" onClick={handleAdd}>
                    +
                </Button>
            </Box>
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    {forcastData?.map((item, index) => {
                        return (
                            <Grid
                                item
                                xs={6}
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <CommonAutoComplete
                                    label="Human Forecast"
                                    name="humanforecasts"
                                    value={item?.humanforecasts}
                                    profileMeasure={profileMeasure}
                                    index={index}
                                    handleChangeProfile={handleChangeForeCastProfile}
                                />
                                <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={deleteForCast ? "long-menu" : undefined}
                                    aria-expanded={deleteForCast ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={(e) => handleClick(e, index, "humanforecasts")}
                                    size="small"
                                >
                                    <MdMoreVert />
                                </IconButton>
                                <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                        "aria-labelledby": "long-button",
                                    }}
                                    anchorEl={anchorEl}
                                    open={deleteForCast}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={() => { handleremove("humanforecasts") }} sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Delete</MenuItem>
                                </Menu>
                            </Grid>
                        );
                    })}
                </Grid>
            </Container>
            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 10px"
                }}
            >
                <Typography variant="h6" component="h6" style={{ fontFamily: "'Poppins', sans-serif" }}>
                    Driver Based Forecast Input Measures
                </Typography>{" "}
                <Button variant="contained" onClick={handleAddDriverMeasure}>
                    +
                </Button>
            </Box>
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={4} style={{ marginTop: "10px" }}>
                    {driverBasedMeasure?.length > 0 && driverBasedMeasure?.map((item, index) => {
                        return (
                            <>
                                <Grid item xs={3}>
                                    <CommonAutoComplete
                                        label="Demand Driver Measure"
                                        name="measure"
                                        value={item?.measure}
                                        profileMeasure={profileMeasure}
                                        index={index}
                                        handleChangeProfile={handleChangeDriverBasedMeasure}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id="categoricalVariable"
                                                name="categoricalVariable"
                                                checked={item?.categoricalVariable || false}
                                                onChange={(e, newValue) => {
                                                    handleChangeDriverBasedMeasure(index, newValue, "categoricalVariable", e);
                                                }}
                                                disabled={isView}
                                                style={{
                                                    padding: "0px !important",
                                                    height: "54px",
                                                    margin: "0px 19px"
                                                }}
                                            />
                                        }
                                        label="Categorical Variable"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="offset"
                                            name="offset"
                                            type="number"
                                            variant="outlined"
                                            fullWidth
                                            label="Offset"
                                            value={item?.offset || ''}
                                            onChange={(e) => {
                                                const newValue = Number(e.target.value);
                                                if (newValue >= 0 && newValue <= 10) {
                                                    handleChangeDriverBasedMeasure(index, newValue, "offset", e);
                                                } else if (newValue > 10) {
                                                    handleChangeDriverBasedMeasure(index, 10, "offset", e);
                                                } else {
                                                    handleChangeDriverBasedMeasure(index, 0, "offset", e);
                                                }
                                            }}
                                            placeholder="Enter offset value"
                                            inputProps={{ min: 0, max: 10 }}
                                        />
                                    </FormControl>

                                </Grid>
                                <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                                    <FormControl fullWidth>
                                        <CommonAutoComplete
                                            label="Impact Measure"
                                            name="impactMeasure"
                                            value={item?.impactMeasure}
                                            profileMeasure={profileMeasure}
                                            index={index}
                                            handleChangeProfile={handleChangeDriverBasedMeasure}
                                        />
                                    </FormControl>
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={deleteDemandDriven ? "long-menu" : undefined}
                                        aria-expanded={deleteDemandDriven ? "true" : undefined}
                                        aria-haspopup="true"
                                        onClick={(e) => handleClick(e, index, "demandDriven")}
                                        size="small"
                                    >
                                        <MdMoreVert />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                            "aria-labelledby": "long-button",
                                        }}
                                        anchorEl={anchorMeasureEl}
                                        open={deleteDemandDriven}
                                        onClose={() => { setAnchorMeasureEl(null) }}
                                    >
                                        <MenuItem onClick={() => { handleremove("demandDriven") }} sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Delete</MenuItem>
                                    </Menu>
                                </Grid>
                            </>
                        )
                    })}
                </Grid>
            </Container>

            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />

            <Typography variant="h6" component="h6" style={{
                marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
            }}>
                Driver Based Forecast Output Measures
            </Typography>
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    {profileFieldData?.driverBased?.map((field, index) => (
                        <Grid item xs={6} key={index}>
                            <FormControl fullWidth>
                                <CommonAutoComplete
                                    label={field.label}
                                    name={field.name}
                                    value={formValues?.[field.name]}
                                    profileMeasure={profileMeasure}
                                    handleChangeProfile={handleChangeProfile}
                                />
                            </FormControl>
                        </Grid>
                    ))}
                </Grid>
            </Container>

            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />

            <Typography variant="h6" component="h6" style={{
                marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
            }}>
                Administrative Info
            </Typography>{" "}
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <Grid item xs={12}>
                        <TextField
                            id="notes"
                            name="notes"
                            variant="outlined"
                            fullWidth
                            label="Notes"
                            value={formValues.notes}
                            multiline
                            rows={6}
                            onChange={(e, newValue) => {
                                handleChangeProfile(newValue, "notes", e);
                            }}
                            placeholder="Enter Notes"
                            disabled={isView}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    {profileFieldData?.administrative?.map((field, index) => (
                        <Grid item xs={6}>
                            <TextField
                                type='date'
                                id={field.name}
                                name={field.name}
                                variant="outlined"
                                label={field?.label}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={formValues?.[field.name]}
                                onChange={(e, newValue) => {
                                    handleChangeProfile(newValue, field.name, e);
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Container>

            <CustomButton
                handleCancel={handleCancel}
                handleSubmit={handleSubmit}
            />
        </div>
    );
};

export default CreateProfile;