import React, { useMemo, useState } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { MdMoreVert } from "react-icons/md";

const EnterpriseMaterialList = ({
  selectedRows,
  data,
  handleTableRowSelect,
  setModalOpenDelete,
  handleCreateUpdate,
  setAttriId,
}) => {
  const [openedMenus, setOpenedMenus] = useState({});

  const handleClick = (rowId) => (event) => {
    setOpenedMenus((prevMenus) => ({
      ...prevMenus,
      [rowId]: event.currentTarget,
    }));
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "enterpriseName",
        enableSorting: false,
        header: "Enterprise Name",
      },
      {
        accessorKey: "type",
        enableSorting: false,
        header: "Type",
      },
      {
        accessorKey: "status",
        enableSorting: false,
        header: "Status",
      },
      {
        accessorKey: "notes",
        enableSorting: false,
        header: "Notes",
      },
      {
        accessorKey: "validFrom",
        enableColumnOrdering: false,
        enableSorting: false,
        header: "Valid From",
      },
      {
        accessorKey: "validTo",
        enableColumnOrdering: false,
        enableSorting: false,
        header: "Valid To",
      },
      {
        accessorKey: "view",
        header: "",
        enableSorting: false,
        enableColumnOrdering: false,

        Cell: ({ row }) => {
          const handleCloseMenu = () => {
            setOpenedMenus(prevState => ({
              ...prevState,
              [row?.original?._id]: null
            }));
          };

          return (
            <>
              <IconButton onClick={handleClick(row.original._id)} size="small">
                <MdMoreVert />
              </IconButton>
              <Menu
                anchorEl={openedMenus[row.original._id]}
                open={Boolean(openedMenus[row.original._id])}
                onClose={() => handleCloseMenu()}
              >
                <MenuItem
                  onClick={() => {
                    handleCreateUpdate(true, row?.original?._id);
                    handleTableRowSelect("", row?.original);
                    handleCloseMenu();
                  }}
                  sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                >
                  Edit Details
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setModalOpenDelete(true);
                    setAttriId(row?.original?._id);
                    handleCloseMenu();
                  }}
                  sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </>
          );
        }

      },
    ],
    [selectedRows, handleTableRowSelect, openedMenus]
  );

  const table = useMaterialReactTable({
    columns,
    data: data || [],
    enableColumnOrdering: true,
    enableSelectAll: false,
    enableRowSelection: false,
    muiSelectCheckboxProps: { color: "secondary" },
    positionToolbarAlertBanner: "head-overlay",
    enableFullScreenToggle: false,
    muiTableHeadCellProps: {
      sx: {
        fontSize: "12px"
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: "12px",
        fontFamily: "'Poppins', sans-serif"
      }
    }
  });

  return <MaterialReactTable table={table} />;
};

export default EnterpriseMaterialList;
