import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { FormControl, Grid } from "@mui/material";
import { createAttribute, getAttributes, getAttributesById } from "../../store/ApiSlice/attributeSlice";
import { useDispatch } from "react-redux";
import { getEnterpriseList } from "../../store/ApiSlice/enterpriseSlice";
import { useSelector } from "react-redux";
import { getEnterModelList } from "../../store/ApiSlice/modelSlice";
import axios from "axios";
import { API_BASE_URL } from "../../helper/constants";

const CreateAttribute = ({
  editRow,
  isCreateUpdateModalOpen,
  setIsCreateUpdateModalOpen,
  isEdit,
  isCreate,
  newData,
  model,
  selectedDimension,
  setDimensionAttributes,
  dimensionAttributes, updatedId,
  dataValues,
  formValueData,
  formData,
  type
}) => {

  let initialVal = { name: "", type: "", length: "", note: "", CustomAttribute: " ", AttributeUID: "" };
  const { enterpriseData } = useSelector((state) => state.enterprise)
  const { modalData } = useSelector((state) => state.modelList)
  const [validations, setValidations] = React.useState(initialVal);
  const [enterPrizeNameData, setEnterPrizename] = useState()
  const dispatch = useDispatch();
  const userId = localStorage.getItem("accessToken");

  useEffect(() => {
    if (formData) {
      const enterPrizename = formData?.filter((item) => item.entermodelUID.enterpriseUID === formValueData?.enterpriseName)
      setEnterPrizename(enterPrizename)
    }
  }, [formData])

  let initialFormValues = {
    name: isEdit && editRow?.name ? editRow?.name : "",
    type: isEdit && editRow?.type ? editRow?.type : "VARCHAR",
    length: isEdit && parseInt(editRow?.length) ? parseInt(editRow?.length) : "",
    notes: isEdit && editRow?.notes ? editRow?.notes : "",
    enterpriseName: isEdit && editRow?.enterpriseName ? editRow?.enterpriseName : "",
    modelUID: isEdit && editRow?.modelUID ? editRow?.modelUID?.id : "",// Set modelUID
    enterpriseUID: isEdit && editRow?.enterpriseUID ? editRow?.enterpriseUID?._id : "",// Set enterpriseUID
    modelName: isEdit && editRow?.modelName ? editRow?.modelName : "",
  };

  const [formValues, setformValues] = useState(initialFormValues);

  const filteredData = modalData?.filter(
    (item) => item.enterpriseUID?._id === formValues.enterpriseUID
  );

  const validateAll = () => {
    const { name, type } = formValues;
    const validations = { name: "", type: "", length: "", note: "", CustomAttribute: " " };
    let isValid = true;
    if (!name) {
      validations.name = "Name is required";
      isValid = false;
    }
    if (!type) {
      validations.type = "Type is required";
      isValid = false;
    }
    setValidations(validations);
    return isValid;
  };

  const validateOne = (e) => {
    const { name } = e.target;
    const value = formValues[name];
    let message = "";
    if (!value) {
      message = `${name} is required`;
    }
    setValidations({ ...validations, [name]: message });
  };

  const handleCloseModal = () => {
    setIsCreateUpdateModalOpen(false);
  };
  const handleAttributeFieldChange = (fieldName) => (event) => {
    const value = event.target.value;
    if (fieldName === 'modelName') {
      const selectedData = filteredData.filter((data) => data.modalUID === value);
      setformValues({
        ...formValues,
        [fieldName]: selectedData[0]?.modelName,
        [`modelUID`]: value
      });
    } else if (fieldName === 'enterpriseName') {
      const selectedData = enterpriseData?.filter((data) => data._id === value);
      setformValues({
        ...formValues,
        [fieldName]: selectedData[0]?.enterpriseName,
        [`enterpriseUID`]: value
      });

    } else {
      setformValues({
        ...formValues,
        [fieldName]: value
      });
    }


  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateAll();
    if (!isValid) {
      return false;
    }
    await saveNewAttribute();
    setformValues(initialVal);
    handleCloseModal();
  };

  const saveNewAttribute = async () => {
    // try {
      if (!formValues.name || !formValues.type) {
        return;
      }
      if (formValues.type === "VARCHAR" && !formValues.length) {
        formValues.length = 1;
      }
      const lengthNumber = (number) => {
        let numLength = Number(number)
        return numLength;
      }
      const updatedValue = {
        ...(formValues?.length && !isNaN(parseInt(formValues?.length)) && {
          length: lengthNumber(parseInt(formValues?.length))
        }),
        name: formValues?.name,
        notes: formValues?.notes,
        type: formValues?.type,
        enterpriseName: formValues?.enterpriseName ? formValues?.enterpriseName : selectedDimension?.entermodelUID?.enterpriseName ? (model ? selectedDimension?.entermodelUID?.enterpriseName : "") : (isCreate ? newData?.enterpriseName : ""),
        enterpriseUID: formValues?.enterpriseUID ? formValues?.enterpriseUID : selectedDimension?.entermodelUID?.enterpriseUID ? (model ? selectedDimension?.entermodelUID?.enterpriseUID : "") : (isCreate ? newData?.enterpriseUID : ""),
        modelName: formValues?.modelName ? formValues?.modelName : selectedDimension?.entermodelUID?.modelName ? (model ? selectedDimension?.entermodelUID?.modelName : "") : (isCreate ? newData?.modelName : ""),
        modelUID: formValues?.modelUID ? formValues?.modelUID : selectedDimension?.entermodelUID?.modalUID ? (model ? selectedDimension?.entermodelUID?.modalUID : "") : (isCreate ? newData?.modelUID : ""),

      };
      const result = await dispatch(createAttribute({ updatedValue, isEdit, userId, updatedId }))
      if (result?.meta?.requestStatus === 'fulfilled') {
        setDimensionAttributes([...dimensionAttributes, result?.payload?.data]);
        // toast.success(isEdit ? "Attribute updated successfully" : "Attribute created successfully");
        dispatch(getAttributes())
      } else if (result?.meta?.requestStatus === 'rejected') {
        toast.error(result?.error?.message || "Something went wrong!");
      }
      dispatch(getAttributes())
    // } catch (error) {
    //   toast.error("There is some error while updating attribute")
    // }
  };

  useEffect(() => {
    if (formValues.type == "REFRENCE") {
      dispatch(getEnterpriseList());
    }
  }, []);


  const [attDarta, setData] = useState([])
  const getEnterprise = async (id) => {
    const userId = localStorage.getItem("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(userId)?.token}`,
      },
    };
    let response = await axios.get(`${API_BASE_URL}/attributes/${updatedId}`, config);
    // setformValues({
    //   ...formValues,
    //   enterpriseUID: response?.data?.data?.modelUID?.enterpriseUID,
    //   modelUID: response?.data?.data?.modelUID
    //     ?.id
    // })
    setData(response?.data?.data);
  };
  useEffect(() => {
    if (updatedId) {
      getEnterprise()
    }
  }, [updatedId])

  useEffect(() => {
    if (editRow) {
      setformValues({
        ...formValues,
        enterpriseUID: editRow?.enterpriseUID?._id,
        modelUID: editRow?.modelUID && editRow?.modelUID?.id
      });
    }
  }, [editRow]);


  useEffect(() => {
    // getEnterpriseList();
    dispatch(getEnterpriseList())
    dispatch(getEnterModelList())
  }, []);

  useEffect(() => {
    if (formValues.enterpriseUID) {
      dispatch(getEnterModelList())
    }
  }, [formValues.enterpriseUID]);

  const renderModalContent = () => {
    return (
      <>
        {isEdit ?
          <>
            <DialogTitle sx={{ fontSize: "16px" }}>Edit Planning Attribute</DialogTitle>
            <DialogContent sx={{ paddingTop: "10px !important" }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="name"
                    label="Planning Attribute name"
                    name="name"
                    variant="outlined"
                    fullWidth
                    value={formValues?.name}
                    onChange={handleAttributeFieldChange("name")}
                    placeholder="Enter attribute name"
                    onBlur={validateOne}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Attribute Type</InputLabel>
                    <Select
                      id="type"
                      label="Attribute Type"
                      name="type"
                      value={formValues?.type}
                      onChange={handleAttributeFieldChange("type")}
                      fullWidth
                      variant="outlined"
                      style={{ marginBottom: "16px" }}
                      onBlur={validateOne}
                    >
                      <MenuItem value="" disabled>
                        Select Type
                      </MenuItem>
                      <MenuItem value="VARCHAR">VARCHAR</MenuItem>
                      <MenuItem value="INTEGER">INTEGER</MenuItem>
                      <MenuItem value="DECIMAL">DECIMAL</MenuItem>
                      <MenuItem value="TIMESTAMP">TIMESTAMP</MenuItem>
                      <MenuItem value="REFERNCE">REFERNCE</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="notes"
                    name="note"
                    label="Planning Attribute notes"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={2}
                    value={formValues?.notes}
                    onChange={handleAttributeFieldChange("notes")}
                    placeholder="Enter attribute note"
                    onBlur={validateOne}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="enterpriseType">Enterprise Name</InputLabel>
                    <Select
                      labelId="enterpriseType"
                      id="demo-simple-select-readonly"
                      value={formValues?.enterpriseUID}
                      fullWidth
                      onChange={handleAttributeFieldChange("enterpriseName")}
                      label="Enterprise Type"
                      variant="outlined"
                    >
                      {enterpriseData?.map((data1) => (
                        <MenuItem key={data1?._id} value={data1?._id}>
                          {data1?.enterpriseName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="modelName">Model Name</InputLabel>
                    <Select
                      labelId="enterpriseType"
                      id="demo-simple-select-readonly"
                      value={formValues?.modelUID}
                      fullWidth
                      onChange={handleAttributeFieldChange("modelName")}
                      label="Model Name"
                      variant="outlined"
                    // disabled={
                    //   formValues.enterpriseName === "" || isEdit ? true : false
                    // }
                    >
                      {filteredData?.map((data1, index) => (
                        <MenuItem key={index} value={data1?.modalUID}>
                          {data1?.modelName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {formValues?.type === "VARCHAR" && (
                  <Grid item xs={6}>
                    <TextField
                      id="length"
                      name="length"
                      label="length"
                      variant="outlined"
                      fullWidth
                      type="number"
                      value={parseInt(formValues?.length)}
                      placeholder="Enter attribute length"
                      onBlur={validateOne}
                      inputProps={{ inputMode: "numeric", min: 1, max: 999 }}
                      onChange={handleAttributeFieldChange("length")}
                    />
                  </Grid>
                )}
                {formValues?.type === "REFERNCE" && (
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="enterpriseType">Attribute List</InputLabel>
                      <Select
                        labelId="enterpriseType"
                        id="demo-simple-select-readonly"
                        value={formValues?.attributeList}
                        fullWidth
                        onChange={handleAttributeFieldChange("attributeList")}
                        label="Enterprise Type"
                        variant="outlined"
                        disabled={isEdit ? true : false}
                      >
                        {enterpriseData?.map((data1) => (
                          <MenuItem key={data1?._id} value={data1?.enterpriseName}>
                            {data1?.enterpriseName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal}>Cancel</Button>
              <Button onClick={handleSubmit} color="primary">
                Save
              </Button>
            </DialogActions>
          </>
          : <>
            <DialogTitle sx={{ fontSize: "16px" }}>New Planning Attribute</DialogTitle>
            <DialogContent sx={{ paddingTop: "10px !important" }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="name"
                    label="Planning Attribute name"
                    name="name"
                    variant="outlined"
                    fullWidth
                    value={formValues.name}
                    onChange={handleAttributeFieldChange("name")}
                    placeholder="Enter attribute name"
                    onBlur={validateOne}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Attribute Type</InputLabel>
                    <Select
                      id="type"
                      label="Attribute Type"
                      name="type"
                      value={formValues.type}
                      onChange={handleAttributeFieldChange("type")}
                      fullWidth
                      variant="outlined"
                      style={{ marginBottom: "16px" }}
                      onBlur={validateOne}
                    >
                      <MenuItem value="" disabled>
                        Select Type
                      </MenuItem>
                      <MenuItem value="VARCHAR">VARCHAR</MenuItem>
                      <MenuItem value="INTEGER">INTEGER</MenuItem>
                      <MenuItem value="DECIMAL">DECIMAL</MenuItem>
                      <MenuItem value="TIMESTAMP">TIMESTAMP</MenuItem>
                      <MenuItem value="REFERNCE">REFERNCE</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="notes"
                    name="note"
                    label="Planning Attribute notes"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={2}
                    value={formValues.notes}
                    onChange={handleAttributeFieldChange("notes")}
                    placeholder="Enter attribute note"
                    onBlur={validateOne}
                  />
                </Grid>
                {model || isCreate ? ("") : type !== "dimension" &&
                  <>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="enterpriseType">Enterprise Name</InputLabel>
                        <Select
                          labelId="enterpriseType"
                          id="demo-simple-select-readonly"
                          value={formValues?.enterpriseUID}
                          fullWidth
                          onChange={handleAttributeFieldChange("enterpriseName")}
                          label="Enterprise Type"
                          variant="outlined"
                        >
                          {enterpriseData?.map((data1) => (
                            <MenuItem key={data1?._id} value={data1?._id}>
                              {data1?.enterpriseName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="modelName">Model Name</InputLabel>
                        <Select
                          labelId="enterpriseType"
                          id="demo-simple-select-readonly"
                          value={formValues?.modelUID}
                          fullWidth
                          onChange={handleAttributeFieldChange("modelName")}
                          label="Model Name"
                          variant="outlined"
                        // disabled={
                        //   formValues.enterpriseName === "" || isEdit ? true : false
                        // }
                        >
                          {filteredData?.map((data1, index) => (
                            <MenuItem key={index} value={data1?.modalUID}>
                              {data1?.modelName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </>}
                {formValues.type === "VARCHAR" && (
                  <Grid item xs={6}>
                    <TextField
                      id="length"
                      name="length"
                      label="length"
                      variant="outlined"
                      fullWidth
                      type="number"
                      value={parseInt(formValues?.length)}
                      placeholder="Enter attribute length"
                      onBlur={validateOne}
                      inputProps={{ inputMode: "numeric", min: 1, max: 999 }}
                      onChange={handleAttributeFieldChange("length")}
                    />
                  </Grid>
                )}
                {formValues.type === "REFERNCE" && (
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="enterpriseType">Attribute List</InputLabel>
                      <Select
                        labelId="enterpriseType"
                        id="demo-simple-select-readonly"
                        value={formValues.attributeList}
                        fullWidth
                        onChange={handleAttributeFieldChange("attributeList")}
                        label="Enterprise Type"
                        variant="outlined"
                        disabled={isEdit ? true : false}
                      >
                        {enterpriseData?.map((data1) => (
                          <MenuItem key={data1?._id} value={data1?.enterpriseName}>
                            {data1?.enterpriseName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal}>Cancel</Button>
              <Button onClick={handleSubmit} color="primary">
                Save
              </Button>
            </DialogActions>
          </>}

      </>
    );
  };

  return (
    <Dialog open={isCreateUpdateModalOpen} onClose={handleCloseModal}>
      {renderModalContent()}
    </Dialog>
  );
};

export default CreateAttribute;
