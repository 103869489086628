import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_BASE_URL } from "../../helper/constants";
import axios from "axios";
import { toast } from "react-toastify";

const getLayoutFromLocalStorage = () => {
    const layoutJSON = localStorage.getItem('layout');
    return layoutJSON ? JSON.parse(layoutJSON) : [];
};

const saveLayoutToLocalStorage = (layout) => {
    localStorage.setItem('layout', JSON.stringify(layout));
};

const getDroppDataFromLocalStorage = () => {
    const layoutJSON = localStorage.getItem('dropped');
    return layoutJSON ? JSON.parse(layoutJSON) : [];
}

const saveDroppDataToLocalStorage = (layout) => {
    localStorage.setItem('dropped', JSON.stringify(layout));
}

const initialState = {
    loading: false,
    error: null,
    success: false,
    canvasData: [],
    pageData: [],
    workBookData: [],
    workData: [],
    workSheetData: [],
    filter: {},
    // layout: [],
    layout: getLayoutFromLocalStorage(),
    dropped: getDroppDataFromLocalStorage(),
    timeDimensionData: {},
    schemaData: [],
    filterDetail: [],
    filterAttribute: [],
    aggregatedViewData: [],
    dimensionFilteredData: [],
    attributeValue: [],
    homePageWidgetData: []
}



export const getCanvasList = createAsyncThunk(
    'canvas/getCanvasList',
    async () => {
        const userId = localStorage.getItem("accessToken");
        const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };

        const response = await axios.get(`${API_BASE_URL}/canvas`, config);
        return response.data;
    }
);

export const getPageList = createAsyncThunk(
    'canvas/getPageList',
    async ({ canvasFindData }) => {
        const userId = localStorage.getItem("accessToken");
        const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };
        const response = await axios.get(`${API_BASE_URL}/page/${canvasFindData?.enterpriseUID?._id}/${canvasFindData?.modelUID?.modalUID}`, config);
        return response.data;
    }
);

export const getWorkBookDetail = createAsyncThunk(
    'canvas/getWorkBookDetail',
    async () => {
        const userId = localStorage.getItem("accessToken");
        const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };

        const response = await axios.get(`${API_BASE_URL}/widget`, config);
        return response.data;
    }
);

export const getPrimaryTimeDimension = createAsyncThunk(
    'canvas/getPrimaryTimeDimension',
    async ({ canvasFindData }) => {
        const userId = localStorage.getItem("accessToken");
        const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };

        const response = await axios.get(`${API_BASE_URL}/dimensions/primaryTimeDimension/${canvasFindData?.enterpriseUID?._id}/${canvasFindData?.modelUID?.modalUID}`, config);
        return response.data;
    }
);

export const getSchemaData = createAsyncThunk(
    'canvas/getSchemaData',
    async ({ timeDimensionData }) => {
        const userId = localStorage.getItem("accessToken");
        const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };

        const response = await axios.get(`${API_BASE_URL}/dataload/${timeDimensionData?._id}`, config);
        return response.data;
    }
);

export const getFilterDetail = createAsyncThunk(
    'canvas/getFilterDetail',
    async () => {
        const userId = localStorage.getItem("accessToken");
        const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };

        const response = await axios.get(`${API_BASE_URL}/filter`, config);
        return response.data;
    }
);

export const getAllFilterAttributeDetail = createAsyncThunk(
    'canvas/getAllFilterAttributeDetail',
    async ({ canvasFindData }) => {
        const userId = localStorage.getItem("accessToken");
        const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };

        const response = await axios.get(`${API_BASE_URL}/attributes/${canvasFindData?.enterpriseUID?._id}/${canvasFindData?.modelUID?.modalUID}`, config);
        return response.data;
    }
);

export const getAttributeValue = createAsyncThunk(
    'canvas/getAttributeValue',
    async ({ row }) => {
        const userId = localStorage.getItem("accessToken");
        const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };

        const response = await axios.get(`${API_BASE_URL}/attributes/values/${row?.attributes}`, config);
        return response.data;
    }
);

export const getHomePageWidget = createAsyncThunk('canvas/getHomePageWidget',
    async () => {
        const userId = localStorage.getItem("accessToken");
        const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };

        const response = await axios.get(`${API_BASE_URL}/home`, config);
        return response.data;
    }
)

export const createHomeWidget = createAsyncThunk('canvas/createHomeWidget',
    async (id) => {
        const userId = localStorage.getItem("accessToken");
        const config = { headers: { Authorization: `Bearer ${JSON.parse(userId)?.token}` } };

        const response = await axios.post(`${API_BASE_URL}/home/addWidget/${id}`, {}, config);
        if (response?.data?.status === "Success") {
            // toast.success(response?.data?.message);
            return response.data;
        } else {
            toast.error(response?.data?.message);
        }
    }
)

export const createUpdateCanvasAsync = createAsyncThunk(
    'canvas/createUpdateCanvasAsync',
    async ({ payload, isEdit, updatedId, updatedCanvasId }) => {
        const userId = localStorage.getItem("accessToken");
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`,
            },
        };
        try {
            let result;
            if (!isEdit) {
                const baseURLCreate = `${API_BASE_URL}/canvas`;
                result = await axios.post(baseURLCreate, payload, config);
            } else {
                const baseURLUpdate = `${API_BASE_URL}/canvas/${updatedId || updatedCanvasId}`;
                result = await axios.put(baseURLUpdate, payload, config);
            }
            return result.data;
        } catch (error) {
            return Promise.reject(error?.response?.data?.message);
        }
    }
);

export const createViewAggregated = createAsyncThunk(
    'canvas/createViewAggregated',
    async ({ payload, editViewData }) => {
        const userId = localStorage.getItem("accessToken");
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`,
            },
        };
        try {
            let result;
            if (!editViewData) {
                const baseURLCreate = `${API_BASE_URL}/measure/dataload/aggregatedView`;
                result = await axios.post(baseURLCreate, payload, config);
                
            } else {
                const baseURLCreate = `${API_BASE_URL}/measure/dataload/storeAggregatedEditedMeasure`;
                result = await axios.put(baseURLCreate, payload, config);
            }
            
            return result.data;
        } catch (error) {
            return Promise.reject(error?.response?.data?.message);
        }
    }
);


export const createDimensionFilteredmasterData = createAsyncThunk(
    'canvas/createDimensionFilteredmasterData',
    async ({ payload, editViewData }) => {
        const userId = localStorage.getItem("accessToken");
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`,
            },
        };
        try {
            let result;
            const baseURLCreate = `${API_BASE_URL}/dataload/getfilteredMasterData`;
            result = await axios.post(baseURLCreate, payload, config);
            return result.data;
        } catch (error) {
            return Promise.reject(error?.response?.data?.message);
        }
    }
);

export const updateViewAggregated = createAsyncThunk(
    'canvas/updateViewAggregated',
    async ({ payload, editViewData }) => {
        const userId = localStorage.getItem("accessToken");
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`,
            },
        };
        try {
            let result;
            const baseURLCreate = `${API_BASE_URL}/measure/dataload/storeAggregatedEditedMeasure`;
            result = await axios.put(baseURLCreate, payload, config);

            return result.data;
        } catch (error) {
            return Promise.reject(error?.response?.data?.message);
        }
    }
);

export const createPageDetail = createAsyncThunk(
    'canvas/createPageDetail',
    async ({ payload, isEdits, updatedId }) => {
        const userId = localStorage.getItem("accessToken");
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`,
            },
        };
        try {
            let result;
            if (!isEdits) {
                const baseURLCreate = `${API_BASE_URL}/page`;
                result = await axios.post(baseURLCreate, payload, config);
            } else {
                const baseURLCreate = `${API_BASE_URL}/page/${updatedId}`;
                result = await axios.put(baseURLCreate, payload, config);
            }
            return result.data;
        } catch (error) {
            return Promise.reject(error?.response?.data?.message);
        }
    }
);

export const createWorkBookDetail = createAsyncThunk(
    'canvas/createWorkBookDetail',
    async ({ payload, openModal, updatedId }) => {
        const userId = localStorage.getItem("accessToken");
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`,
            },
        };
        try {
            let result;
            if (!openModal) {
                const baseURLCreate = `${API_BASE_URL}/widget`;
                result = await axios.post(baseURLCreate, payload, config);
            } else {
                const baseURLCreate = `${API_BASE_URL}/widget/${updatedId}`;
                result = await axios.put(baseURLCreate, payload, config);
            }
            return result.data;
        } catch (error) {
            return Promise.reject(error?.response?.data?.message);
        }
    }
);

export const createFilterDetail = createAsyncThunk(
    'canvas/createFilterDetail',
    async ({ payload, isEdit, selectedRows }) => {
        const userId = localStorage.getItem("accessToken");
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`,
            },
        };
        try {
            let result;
            if (!isEdit) {
                const baseURLCreate = `${API_BASE_URL}/filter`;
                result = await axios.post(baseURLCreate, payload, config);
            } else {
                const baseURLCreate = `${API_BASE_URL}/filter/${selectedRows}`;
                result = await axios.put(baseURLCreate, payload, config);
            }
            return result.data;
        } catch (error) {
            return Promise.reject(error?.response?.data?.message);
        }
    }
);

export const deleteCanvasList = createAsyncThunk('canvas/deleteCanvasList',
    async ({ attriId, config }) => {
        try {
            const responce = await axios.delete(`${API_BASE_URL}/canvas/${attriId}`, config)
            return responce.data
        } catch (error) {
            return Promise.reject(error?.response?.data?.error);
        }
    }
);

export const deletePageList = createAsyncThunk('canvas/deletePageList',
    async ({ attriId, config }) => {
        try {
            const responce = await axios.delete(`${API_BASE_URL}/page/${attriId}`, config)
            return responce.data
        } catch (error) {
            return Promise.reject(error?.response?.data?.error);
        }
    }
);

export const deleteFilterList = createAsyncThunk('canvas/deleteFilterList',
    async ({ attriId, config }) => {
        try {
            const responce = await axios.delete(`${API_BASE_URL}/filter/${attriId}`, config)
            return responce.data
        } catch (error) {
            return Promise.reject(error?.response?.data?.error);
        }
    }
);

export const deleteWidget = createAsyncThunk('canvas/deleteWidget',
    async ({ attriId, config }) => {
        try {
            const responce = await axios.delete(`${API_BASE_URL}/widget/${attriId}`, config)
            return responce.data
        } catch (error) {
            return Promise.reject(error?.response?.data?.error);
        }
    }
);

export const deleteHomeWidget = createAsyncThunk('canvas/deleteHomeWidget',
    async ({ attriId, config }) => {
        try {
            const responce = await axios.delete(`${API_BASE_URL}/home/removeWidget/${attriId}`, config)
            return responce.data
        } catch (error) {
            return Promise.reject(error?.response?.data?.error);
        }
    }
);

const canvasSlice = createSlice({
    name: "canvas",
    initialState,
    reducers: {
        setWorkData(state, action) {
            const newData = action.payload;
            state.workData = [...state.workData, ...newData];
        },
        setAggregatedViewData(state, action) {
            state.aggregatedViewData = action.payload
        },
        setFilter(state, action) {
            state.filter = action.payload
        },
        setLayout(state, action) {
            state.layout = [...action.payload];
            saveLayoutToLocalStorage(state.layout);
        },
        setDropped(state, action) {
            state.dropped = [...action.payload];
            saveDroppDataToLocalStorage(state.dropped)
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCanvasList.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getCanvasList.fulfilled, (state, action) => {
                state.loading = true;
                state.error = null;
                state.canvasData = action?.payload?.data;
            })
            .addCase(getCanvasList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getPageList.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getPageList.fulfilled, (state, action) => {
                state.loading = true;
                state.error = null;
                state.pageData = action?.payload?.data;
            })
            .addCase(getPageList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getWorkBookDetail.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getWorkBookDetail.fulfilled, (state, action) => {
                state.loading = true;
                state.error = null;
                state.workBookData = action?.payload?.data;
            })
            .addCase(getWorkBookDetail.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getPrimaryTimeDimension.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getPrimaryTimeDimension.fulfilled, (state, action) => {
                state.loading = true;
                state.error = null;
                state.timeDimensionData = action?.payload?.data;
            })
            .addCase(getPrimaryTimeDimension.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getSchemaData.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getSchemaData.fulfilled, (state, action) => {
                state.loading = true;
                state.error = null;
                state.schemaData = action?.payload?.data;
            })
            .addCase(getSchemaData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getFilterDetail.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getFilterDetail.fulfilled, (state, action) => {
                state.loading = true;
                state.error = null;
                state.filterDetail = action?.payload?.data;
            })
            .addCase(getFilterDetail.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getAllFilterAttributeDetail.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getAllFilterAttributeDetail.fulfilled, (state, action) => {
                state.loading = true;
                state.error = null;
                state.filterAttribute = action?.payload?.data;
            })
            .addCase(getAllFilterAttributeDetail.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getAttributeValue.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getAttributeValue.fulfilled, (state, action) => {
                state.loading = true;
                state.error = null;
                state.attributeValue = action?.payload?.data;
            })
            .addCase(getAttributeValue.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getHomePageWidget.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getHomePageWidget.fulfilled, (state, action) => {
                state.loading = true;
                state.error = null;
                state.homePageWidgetData = action?.payload?.data?.widgets;
            })
            .addCase(getHomePageWidget.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(createUpdateCanvasAsync.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(createUpdateCanvasAsync.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(createUpdateCanvasAsync.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
            
            .addCase(createHomeWidget.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(createHomeWidget.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(createHomeWidget.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
            .addCase(createViewAggregated.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(createViewAggregated.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.aggregatedViewData = action.payload;
            })
            .addCase(createViewAggregated.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
            .addCase(createDimensionFilteredmasterData.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(createDimensionFilteredmasterData.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.dimensionFilteredData = action.payload;
            })
            .addCase(createDimensionFilteredmasterData.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
            .addCase(updateViewAggregated.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(updateViewAggregated.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(updateViewAggregated.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
            .addCase(createPageDetail.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(createPageDetail.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(createPageDetail.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
            .addCase(createWorkBookDetail.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(createWorkBookDetail.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true
            })
            .addCase(createWorkBookDetail.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
            .addCase(createFilterDetail.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(createFilterDetail.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.filter = action?.payload?.data;
            })
            .addCase(createFilterDetail.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
            .addCase(deleteCanvasList.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(deleteCanvasList.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(deleteCanvasList.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
            .addCase(deletePageList.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(deletePageList.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(deletePageList.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
            .addCase(deleteFilterList.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(deleteFilterList.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(deleteFilterList.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
            .addCase(deleteWidget.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(deleteWidget.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(deleteWidget.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
            .addCase(deleteHomeWidget.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(deleteHomeWidget.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(deleteHomeWidget.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.error.message;
            })
    }
})

export const { setWorkData, setFilter, setLayout, setLayoutData, setDropped, setAggregatedViewData } = canvasSlice.actions;
export default canvasSlice.reducer;
