import { useMemo, useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { MdMoreVert } from "react-icons/md";
import moment from "moment";

const EntermodalMaterialList = ({
  setViewMore,
  data,
  selectedRows,
  handleTableRowSelect,
  setAttriId,
  setModalOpenDelete,
  handleCreateUpdate,
  setModalType
}) => {
  const [openedMenus, setOpenedMenus] = useState({});
  const sortedModelData = useMemo(() => {
    return [...(data || [])].sort((a, b) => {
      const modelA = a.modelName.toLowerCase();
      const modelB = b.modelName.toLowerCase();
      if (modelA < modelB) return -1;
      if (modelA > modelB) return 1;
      return 0;
    });
  }, [data]);
  const handleClick = (rowId) => (event) => {
    setOpenedMenus((prevMenus) => ({
      ...prevMenus,
      [rowId]: event.currentTarget,
    }));
  };
  const handleClose = (rowId) => () => {
    setOpenedMenus((prevMenus) => ({
      ...prevMenus,
      [rowId]: null,
    }));
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "modelName",
        enableColumnOrdering: false,
        header: "Model Name",
        enableSorting: false,
      },
      {
        accessorKey: "enterpriseName",
        header: "Enterprise Name",
        enableSorting: false,
      },
      {
        accessorKey: "notes",
        header: "Notes",
        enableSorting: false,
      },
      {
        accessorKey: "reference",
        header: "Reference",
        enableSorting: false,
        Cell: ({ row }) => (
          <>
            <p>{row?.original?.reference === true ? "Yes" : "No"}</p>
          </>
        )
      },
      {
        accessorKey: "createdOn",
        enableColumnOrdering: false, //disable column ordering for this column,
        header: "Created On",
        enableSorting: false,
        Cell: ({ row }) => (
          <>
            <p>{moment(row?.original?.createdOn).format('MM-DD-YYYY')} </p>
          </>
        )
      },
      {
        accessorKey: "view",
        header: "",
        enableSorting: false,
        enableColumnOrdering: false,
        Cell: ({ row }) => {
          const handleCloseMenu = () => {
            setOpenedMenus(prevState => ({
              ...prevState,
              [row.original.id]: null
            }));
          };
          return (

            <>
              <IconButton onClick={handleClick(row.original.id)} size="small">
                <MdMoreVert />
              </IconButton>
              <Menu
                anchorEl={openedMenus[row.original.id]}
                open={Boolean(openedMenus[row.original.id])}
                onClose={handleClose(row.original.id)}
              >
                <MenuItem
                  onClick={() => {
                    setViewMore(true);
                    setAttriId(row?.original?.id);
                    handleCloseMenu();
                  }}
                >
                  View Detail
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCreateUpdate(true, row?.original?.id);
                    handleTableRowSelect("", row?.original);
                    handleCloseMenu();
                    setModalType(row?.original?.type)
                  }}
                >
                  Edit Details
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setModalOpenDelete(true);
                    setAttriId(row?.original?.id);
                    handleCloseMenu();
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </>
          )
        },
      },
    ],
    [selectedRows, handleTableRowSelect, openedMenus]
  );

  const table = useMaterialReactTable({
    columns,
    data: sortedModelData || [],
    enableColumnOrdering: true,
    enableSelectAll: false,
    enableRowSelection: false,
    muiSelectCheckboxProps: { color: "secondary" },
    positionToolbarAlertBanner: "head-overlay",
    enableFullScreenToggle: false,
    muiTableHeadCellProps: {
      sx: {
        fontSize: "12px"
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: "12px",
        fontFamily: "'Poppins', sans-serif"
      }
    }
  });
  return <MaterialReactTable table={table} />;
};

export default EntermodalMaterialList;
