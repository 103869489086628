import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import "react-toastify/dist/ReactToastify.css";
import { registerAllModules } from "handsontable/registry";
import DeleteDimension from "./DeleteDimension";
import CreateUpdateDimension from "./CreateDimension/CreateDimension";
import "../../style/common.css";
import { API_BASE_URL } from "../../helper/constants";
import MiniDrawer from "../common/drawer/DrawerToggle";
import { Box, Tooltip } from "@mui/material";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import { Shuffle } from "../../components/attributes/shuffule";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DimensionsMaterialList from "./DimensionsMaterialList";
import { useDispatch } from "react-redux";
import { getModelListAsync } from "../../store/ApiSlice/dimensionSlice";
import { useSelector } from "react-redux";
import Loader from "../loader/Loader";
import { ThemeContext } from "../../ThemeContext";

const coloumNames = [
  "Attribute name",
  "Type",
  "Notes",
  "ChangedOn",
  "CreatedOn",
  "enterpriseName",
  "modelName",
];

const DimensionList = ({ modelEnterprise }) => {
  const { isDarkTheme } = useContext(ThemeContext);
  const [data, setData] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [checkedItems, setCheckedItems] = useState(coloumNames);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [updatedId, setUpdatedId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState([])
  const [selectNewData, setSelectNewData] = useState(null)
  const [loader, setLoader] = useState(false)
  const checkList = coloumNames;
  const dispatch = useDispatch();
  // const { loading, error, modelList } = useSelector((state) => state.dimension);
  const { dimensionData } = useSelector((state) => state?.dimension);

  useEffect(() => {
    dispatch(getModelListAsync());
  }, []);

  useEffect(() => {
    registerAllModules();
  }, [isCreate]);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSubmit = () => {
    handleCloseModal();
  };

  const handleChange = (event) => {
    setCheckedItems(
      event.target.checked
        ? [...checkedItems, event.target.name]
        : checkedItems.filter((item) => item !== event.target.name)
    );
  };


  const handleTableRowSelect = (event, row) => {
    let id = event.target.value;
    let updatedIds = selectedRows.includes(row?.original?._id)
      ? selectedRows.filter((n) => n !== row?.original?._id)
      : [...selectedRows, row?.original?._id];
    setSelectedRows(updatedIds);
  };
  const handleCreateUpdate = (edit, id) => {
    setUpdatedId(id);
    setIsEdit(edit);
    setIsCreate(true);
  };
  const handleView = (id) => {
    setUpdatedId(id);
    setIsView(true);
  };
  const handleOpenModal = (row) => {
    setSelectNewData(row)
    setOpenModal(true);
  };

  const handleCloseModals = () => {
    setOpenModal(false);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const fileInput = document.getElementById("file-input");
    const file1 = fileInput.files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
    };
  };
  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);

  const handleUploadFile = async (selectedData) => {
    if (selectedData !== null) {
      try {
        setLoader(true)
        const fileInput = document.getElementById("file-input");
        const file = fileInput.files[0];
        // Read the content of the Excel file
        const reader = new FileReader();
        reader.onload = function (e) {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet);
          const userId = localStorage.getItem("accessToken");
          const config = {
            headers: {
              Authorization: `Bearer ${JSON.parse(userId)?.token}`, // Replace yourToken with the actual token
            },
          };
          const updatedData = {
            enterpriseName:
              selectNewData?.original?.entermodelUID?.enterpriseName,
            modelName: selectNewData?.original?.entermodelUID?.modelName,
            dimensionName: selectNewData?.original?.name,
            documentsArray: jsonData,
            clearAll: true,
          };
          axios
            .post(
              `${API_BASE_URL}/dataload/upload`,
              updatedData, // Send the JSON data directly in the request body
              config
            )
            .then((data) => {
              if (data.status) {
                if (data?.data?.data?.erroredItems?.length > 0) {
                  setErrorMessage(data?.data?.data)
                  downloadErrorExcel(data?.data?.data.erroredItems);
                  setLoader(false)
                  setOpenErrorModal(true)
                } else {
                  // toast.success("Successfully data uploaded");
                  handleCloseModals();
                  setLoader(false)
                }
              }
              // Handle successful upload
            })
            .catch((error) => {
              setLoader(false)

              if (
                error?.response &&
                error?.response?.data &&
                error?.response?.data?.data
              ) {
                setLoader(false)
                toast.error(error.response.data.data);
              } else {
                setLoader(false)
                toast.error("Failed to upload data. Please try again later.");
              }
              handleCloseModals();
            });
        };

        reader.readAsArrayBuffer(file);
      } catch (error) {
        setLoader(false)
      }
    }
  };

  // Event handler for the "Download" button
  const handleDownload = (row) => {
    const userId = localStorage.getItem("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(userId)?.token}`, // Replace yourToken with the actual token
      },
    };
    axios
      .get(`${API_BASE_URL}/dataload/${row?.original?._id}`, config)
      .then((items) => {
        const headers = Object.keys(items?.data?.schemaInfo)
          .filter(key => items.data.data[0].hasOwnProperty(key))
          .filter((key) => key !== "_id" && key !== "__v")
          .sort((a, b) => items?.data?.schemaInfo[a].order - items?.data?.schemaInfo[b].order);
        // const headers = keys
        //   .filter((key) => key !== "_id" && key !== "__v")
        //   .sort();
        const rows = items.data.data.map((obj) => {
          let temp = {};
          headers.forEach((key) => {
            temp[key] = obj[key];
          });
          return Object.values(temp);
        });
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);
        XLSX.utils.book_append_sheet(wb, ws, "Dimensions");

        // Convert the workbook to a binary string
        const wbout = XLSX.write(wb, { type: "binary", bookType: "xlsx" });

        // Trigger file download
        const blob = new Blob([s2ab(wbout)], {
          type: "application/octet-stream",
        });
        saveAs(blob, `vyan_${row?.original?.name}.xlsx`);
      })
      .catch((error) => {
        console.log("🚀 ~ handleDownload ~ error:", error)
      });
    // Prepare table data - Replace 'data' with your actual table data array
  };

  const downloadErrorExcel = (erroredItems) => {
    const transformedData = erroredItems.map(item => {
      const combined = { ...item.item };
      for (const [key, value] of Object.entries(item.errors)) {
        combined[`${key}`] = value;
      }
      return combined;
    });
    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Errors");
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'errored_items.xlsx';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  // Function to convert string to ArrayBuffer
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  // Function to trigger file download
  const saveAs = (blob, filename) => {
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };
  return (
    <>
      {!isCreate && !isView && (
        <>
          {/* <Box sx={{ display: "flex" }}>
            <MiniDrawer />
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                p: 3,
                overflow: "auto",
                height: "100vh",
                background: isDarkTheme ? "whitesmoke" : "white",
              }}
            > */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // marginTop: "40px",
              "@media (max-width: 768px)": {
                flexDirection: "column",
                // marginTop: "60px",
                // marginBottom: "20px",
              },
            }}
          >
            <h3 className="heading"> Dimensions</h3>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Dialog
                open={openModal}
                onClose={handleCloseModals}
                sx={{ padding: "20px" }}
              >
                <DialogTitle>Upload File</DialogTitle>
                <DialogContent>
                  <input
                    id="file-input"
                    type="file"
                    accept=".xlsx, .xls"
                    onChange={handleFileChange}
                  />
                </DialogContent>
                <DialogActions sx={{ gap: "10px" }}>
                  <Button
                    onClick={handleCloseModals}
                    sx={{
                      color: "#8280bd",
                      margin: "0 4px",
                      border: "1px solid #8280bd",
                    }}
                  >
                    Cancel
                  </Button>
                  {loader ? <Loader /> : (
                    <Button
                      // onClick={handleCloseModals}
                      onClick={handleUploadFile}
                      variant="contained"
                      autoFocus
                      className="awesome-button"
                    >
                      Upload
                    </Button>
                  )}
                </DialogActions>
              </Dialog>
              {/* <Dialog
                    open={openErrorModal}
                    onClose={handleCloseModals}
                    sx={{ padding: "20px" }}
                  >
                    <DialogTitle>
                      {errorMessage?.erroredItems?.map((item) => {
                        return (
                          <ul>
                            {Object.entries(item?.errors).map(([key, value]) => (
                              <li key={key}>{key}: {value}</li>
                            ))}
                          </ul>
                        )
                      })}
                    </DialogTitle>
                    <DialogActions>
                      <Button
                        onClick={() => setOpenErrorModal(false)}
                        sx={{
                          color: "#8280bd",
                          margin: "0 4px",
                          border: "1px solid #8280bd",
                        }}
                      >
                        Cancel
                      </Button>
                    </DialogActions>
                  </Dialog> */}
              {/* <SettingsOutlinedIcon
                    onClick={handleClick}
                    style={{
                      marginRight: "16px",
                      cursor: "pointer",
                      transition: "background-color 0.3s",
                      ":hover": { backgroundColor: "#e0e0e0" },
                    }}
                  /> */}
              <Tooltip title="Add Dimension">
                <Button
                  onClick={() => {
                    handleCreateUpdate(false);
                  }}
                  className="awesome-button"
                  sx={{ color: "white", margin: "0 4px", fontSize: "1.875rem !important" }}
                >
                  +
                </Button>
              </Tooltip>
              {/* <Button
                    onClick={() => {
                      handleCreateUpdate(true);
                    }}
                    className={
                      selectedRows.length
                        ? "awesome-button"
                        : "awesome-button-disabled"
                    }
                    disabled={!selectedRows.length}
                    sx={{ color: "white", margin: "0 4px" }}
                  >
                    Edit
                  </Button> */}
              <DeleteDimension
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                setModalDeleteOpen={setModalDeleteOpen}
                isModalDeleteOpen={isModalDeleteOpen}
                updatedId={updatedId}
              // getDimensions={getDimensions}
              />
            </div>
          </Box>
          <DimensionsMaterialList
            modelEnterprise={modelEnterprise}
            data={dimensionData}
            handleTableRowSelect={handleTableRowSelect}
            setModalDeleteOpen={setModalDeleteOpen}
            handleOpenModal={handleOpenModal}
            handleDownload={handleDownload}
            handleCreateUpdate={handleCreateUpdate}
            handleView={handleView}
            setUpdatedId={setUpdatedId}
          />
          <Dialog open={isModalOpen} onClose={handleCloseModal}>
            <>
              <DialogTitle>View Setting</DialogTitle>
              <hr />
              <DialogContent>
                <DndProvider backend={HTML5Backend}>
                  <Shuffle
                    checkList={checkList}
                    checkedItems={checkedItems}
                    handleChange={handleChange}
                  />
                </DndProvider>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseModal}>Cancel</Button>
                <Button onClick={handleSubmit} color="primary">
                  Save
                </Button>
              </DialogActions>
            </>
          </Dialog>
          {/* </Box>
          </Box> */}
        </>
      )}
      {(isCreate || isView) && (
        <CreateUpdateDimension
          setIsCreate={setIsCreate}
          setIsEdit={setIsEdit}
          isEdit={isEdit}
          isCreate={isCreate}
          selectedRows={updatedId}
          data={dimensionData}
          isView={isView}
          setUpdatedId={setUpdatedId}
          handleView={handleView}
          setIsView={setIsView}
        />
      )}


    </>
  );
};

export default DimensionList;
