import { IconButton, Menu, MenuItem } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import React, { useEffect, useMemo, useState } from 'react'
import { MdMoreVert } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { getSuperAdministrator } from '../../store/ApiSlice/superAdministratorSlice';
import { useSelector } from 'react-redux';

const UsermaterialList = ({
    handleTableRowSelect,
    setUpdatedId,
    handleCreateUpdate,
    setModalDeleteOpen
}) => {
    const { userData } = useSelector((state) => state.user)
    const [openedMenus, setOpenedMenus] = useState({});
    const dispatch = useDispatch()

    const handleClick = (rowId) => (event) => {
        setOpenedMenus((prevMenus) => ({
            ...prevMenus,
            [rowId]: event.currentTarget,
        }));
    };

    const handleClose = (id) => {
        setOpenedMenus(prevState => ({
            ...prevState,
            [id]: null
        }));
    };

    useEffect(() => {
        dispatch(getSuperAdministrator())
    }, [])

    const columns = useMemo(() => [
        {
            accessorKey: "enterpriseName",
            enableColumnOrdering: false,
            header: "Enterprise",
            enableSorting: false,
            Cell: ({ row }) => (
                <>
                    <p>{row?.original?.enterprise?.enterpriseName}</p>
                </>
            )
        },
        {
            accessorKey: "userId",
            enableColumnOrdering: false,
            header: "user Id",
            enableSorting: false,
            Cell: ({ row }) => (
                <>
                    <p>{row?.original?.userID}</p>
                </>
            )
        },
        {
            accessorKey: "firstName",
            enableColumnOrdering: false,
            header: "First Name",
            enableSorting: false,
            Cell: ({ row }) => (
                <>
                    <p>{row?.original?.firstName}</p>

                </>
            )
        },
        {
            accessorKey: "lastName",
            enableColumnOrdering: false,
            header: "Last Name",
            enableSorting: false,
            Cell: ({ row }) => (
                <>
                    <p>{row?.original?.lastName}</p>

                </>
            )
        },
        {
            accessorKey: "locked",
            enableColumnOrdering: false,
            header: "Locked",
            enableSorting: false,
            Cell: ({ row }) => (
                <>
                    <p>{row?.original?.locked === false ? "No" : "Yes"}</p>

                </>
            )
        },
        {
            accessorKey: "email",
            enableColumnOrdering: false,
            header: "Email",
            enableSorting: false,
            Cell: ({ row }) => (
                <>
                    <p>{row?.original?.email}</p>

                </>
            )
        },
        {
            accessorKey: "notes",
            enableColumnOrdering: false,
            header: "Notes",
            enableSorting: false,
            Cell: ({ row }) => (
                <>
                    <p>{row?.original?.note ? row?.original?.note : "-"}</p>

                </>
            )
        },
        {
            accessorKey: "view",
            header: "",
            enableSorting: false,
            enableColumnOrdering: false,
            Cell: ({ row }) => {
                return (
                    <>
                        <IconButton onClick={handleClick(row.original._id)} size="small">
                            <MdMoreVert />
                        </IconButton>
                        <Menu
                            anchorEl={openedMenus[row.original._id]}
                            open={Boolean(openedMenus[row.original._id])}
                            onClose={() => { handleClose(row.original._id) }}
                        >
                            <MenuItem
                                onClick={() => {
                                    handleClose(row.original._id);
                                    handleCreateUpdate(true, row?.original?._id);
                                }}
                                sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                            >
                                Edit User
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    handleClose(row.original._id);
                                    setUpdatedId(row.original._id);
                                    setModalDeleteOpen(true);
                                }}
                                sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                            >
                                Delete User
                            </MenuItem>
                        </Menu>
                    </>
                )
            }
        }
    ])
    const table = useMaterialReactTable({
        columns: columns.filter(Boolean),
        data: userData || [],
        enableColumnOrdering: true,
        enableSelectAll: false,
        enableRowSelection: false,
        muiSelectCheckboxProps: { color: "secondary" },
        positionToolbarAlertBanner: "head-overlay",
        enableFullScreenToggle: false,
        enableColumnPinning: true,
        muiTableHeadCellProps: {
            sx: {
                fontSize: "12px"
            },
        },
        muiTableBodyCellProps: {
            sx: {
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif"
            }
        }
    });

    return <MaterialReactTable table={table} />
}

export default UsermaterialList
