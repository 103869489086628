import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Menu, Select, InputLabel } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdMoreVert } from 'react-icons/md';
import { createPageDetail, createWorkBookDetail, getCanvasList, getPageList } from '../../store/ApiSlice/canvasSlice';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getExcuteProcess, getProcessList } from '../../store/ApiSlice/processSlice';

const CanvasMaterialList = ({
    setModalOpenDelete,
    setAttriId,
}) => {
    const dispatch = useDispatch();
    const { canvasData } = useSelector((state) => state.canvas)
    const [openedMenus, setOpenedMenus] = useState({});
    const [sorting, setSorting] = useState([]);
    const navigate = useNavigate()

    const sortedCanvasData = useMemo(() => {
        return [...(canvasData || [])].sort((a, b) => {
            const canvasA = a.name.toLowerCase();
            const canvasB = b.name.toLowerCase();
            if (canvasA < canvasB) return -1;
            if (canvasA > canvasB) return 1;
            return 0;
        });
    }, [canvasData]);

    useEffect(() => {
        dispatch(getCanvasList())
        dispatch(getPageList())
        dispatch(getProcessList())
    }, [])

    const handleClick = (rowId) => (event) => {
        setOpenedMenus((prevMenus) => ({
            ...prevMenus,
            [rowId]: event.currentTarget,
        }));
    };

    const columns = useMemo(() => [

        {
            accessorKey: "name",
            header: "Canvas Name",
            enableSorting: true,
            Cell: ({ row }) => {
                return (
                    <>
                        {row?.original?.name}
                    </>
                )
            },
        },
        {
            accessorKey: "enterpriseName",
            header: "Enterprise Name",
            Cell: ({ row }) => {
                return (
                    <div>{row?.original?.enterpriseUID?.enterpriseName}</div>
                )
            },
            enableSorting: false,
        },

        {
            accessorKey: "modelname",
            header: "Model Name",
            Cell: ({ row }) => {
                return (
                    <div>{row?.original?.modelUID?.modelName}</div>
                )
            },
            enableSorting: false,
        },
        {
            accessorKey: "createdOn",
            header: "Created On",
            Cell: ({ row }) => {
                return (
                    <div>{moment(row?.original?.createdOn).format("YYYY-MM-DD")}</div>
                )
            },
            enableSorting: false,
        },
        {
            accessorKey: "changedOn",
            header: "Changed On",
            Cell: ({ row }) => {
                return (
                    <div>{moment(row?.original?.createdOn).format("YYYY-MM-DD")}</div>
                )
            },
            enableSorting: false,
        },
        {
            accessorKey: "actions",
            enableSorting: false,
            enableColumnOrdering: false,
            Cell: ({ row }) => {
                const handleCloseMenu = (id) => {
                    setOpenedMenus(prevState => ({
                        ...prevState,
                        [id]: null
                    }));
                };
                return (
                    <>
                        <IconButton onClick={handleClick(row.original._id)} size="small">
                            <MdMoreVert />
                        </IconButton>
                        <Menu
                            anchorEl={openedMenus[row.original._id]}
                            open={Boolean(openedMenus[row.original._id])}
                            onClose={() => handleCloseMenu(row?.original?._id)}
                        >
                            <MenuItem
                                onClick={() => {
                                    navigate(`/Canvas/${row?.original?._id}?isEdit=true`);
                                    handleCloseMenu(row?.original?._id);
                                }}
                            >
                                Launch Canvas
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setModalOpenDelete(true);
                                    setAttriId(row?.original?._id);
                                    handleCloseMenu(row?.original?._id);
                                }}
                            >
                                Delete Canvas
                            </MenuItem>
                        </Menu>
                    </>
                )
            }
        }
    ], [openedMenus])

    const table = useMaterialReactTable({
        columns,
        data: sortedCanvasData || [],
        enableColumnOrdering: true,
        enableSelectAll: false,
        enableRowSelection: false, //enable row selection conditionally per row
        muiSelectCheckboxProps: { color: "secondary" },
        positionToolbarAlertBanner: "head-overlay",
        enableFullScreenToggle: false,
        enableSorting: true, // Enable sorting
        state: { sorting }, // Pass the sorting state
        onSortingChange: setSorting,
        muiTableHeadCellProps: {
            sx: {
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif"
            },
        },
        muiTableBodyCellProps: {
            sx: {
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif"
            }
        }
    })
    return (
        <>
            <MaterialReactTable table={table} />
        </>
    )
}

export default CanvasMaterialList
