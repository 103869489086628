import { IconButton, Menu, MenuItem } from '@mui/material';
import { MdMoreVert } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { styled, alpha } from '@mui/material/styles';
import { createWidgetLayout, setPending } from '../../../store/ApiSlice/widgetSlice';
import { useSelector } from 'react-redux';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const CanvasMore = ({
  item,
  newPageID,
  handleClickMenu,
  openedMenus,
  handleCloseMenu,
  handleCreateWidget,
  handleCreateUpdate,
  handleTableRowSelect,
  setModalOpenDelete,
  setAttriId,
  handleOpenWidget,
  getScenarioData,
  setDropped,
  anchorElWidget,
  openMenu,
  handleCloseWidget,
  setGeneralModel,
  setAnchorElWidget,
  setOpenedMenus,
  formValues,
  setFormValues
}) => {
  const dispatch = useDispatch();
  const { canvasData, pageData, layout, workBookData, filterDetail } = useSelector(
    (state) => state.canvas
  );
  const handleClickEvent = (event, type) => {
    event.stopPropagation();
    handleCloseMenu(item?._id);
    handleCreateWidget(item);
    setGeneralModel(type);
    handleCloseWidget();
    setAnchorElWidget(null);
    setOpenedMenus({ id: null });
    dispatch(setDropped([]));
    dispatch(setPending([]))
    setFormValues({ ...formValues, widgetType: type, widgetSubType: "Measure Data" })
  };

  const createWidgetlayoutdata = async () => {
    const body = {
      pageUID: newPageID,
      widgets: layout?.map((row) => ({
        widgetSource: "Created",
        widgetUID: row?.widgetId,
        coordinates: {
          x: row?.x,
          v: row?.y,
          w: row?.w,
          h: row?.h,
          i: row?.i,
          minW: row?.minW,
        }
      }))
    };
    const result = await dispatch(createWidgetLayout(body))
    // if () {

    // }
  }


  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        fontSize: "12px",
        textTransform: "none"
      }}
    >
      {item?.name}
      <IconButton onClick={handleClickMenu(item?._id)} size="small">
        <MdMoreVert />
      </IconButton>
      <Menu
        anchorEl={openedMenus[item?._id]}
        open={Boolean(openedMenus[item?._id])}
        onClose={() => handleCloseMenu(item?._id)}
      >
        <MenuItem
          onClick={() => {
            handleCreateUpdate(true, item?._id);
            handleTableRowSelect('', item?._id);
            handleCloseMenu(item?._id);
          }}
        >
          Edit Page
        </MenuItem>
        <MenuItem
          onClick={() => {
            setModalOpenDelete(true);
            setAttriId(item?._id);
          }}
        >
          Delete Page
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleOpenWidget(e);
            getScenarioData();
            dispatch(setDropped([]));
            dispatch(setPending([]))
          }}
        >
          Add Widget
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            createWidgetlayoutdata()
            handleCloseMenu(item?._id);
          }}
        >
          Save layout
        </MenuItem>
      </Menu>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorElWidget}
        open={openMenu}
        onClose={() => {
          handleCloseWidget();
          handleCloseMenu(item?._id);
        }}
        disableRipple
      >
        <MenuItem
          onClick={(e) => {
            handleClickEvent(e, 'Worksheet');
          }}
        >
          WorkSheet
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleClickEvent(e, 'Chart');
          }}
        >
          Chart
        </MenuItem>
        <MenuItem>Alert</MenuItem>
        <MenuItem>Thread</MenuItem>
      </StyledMenu>
    </div>
  );
};

export default CanvasMore;
