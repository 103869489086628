import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tab, Tabs, Typography, Tooltip } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useRef, useState } from 'react'
import { MdFullscreen, MdFullscreenExit, MdMoreVert } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { TabContext, TabPanel } from '@mui/lab';
import { createDimensionFilteredmasterData, createHomeWidget, createViewAggregated, deleteWidget, getAllFilterAttributeDetail, getCanvasList, getFilterDetail, getHomePageWidget, getPageList, getPrimaryTimeDimension, getSchemaData, getWorkBookDetail, setDropped, setLayout, setLayoutData } from '../../store/ApiSlice/canvasSlice';
import { toast } from 'react-toastify';
import 'react-data-grid/lib/styles.css';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { fetchDimensions } from '../../store/ApiSlice/dimensionSlice';
import RGL, { WidthProvider } from "react-grid-layout";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import MyGridComponent from './MyGridComponent';
import { getScenarios } from '../../store/ApiSlice/processSlice';
import '../page/page.css'
import { setPending } from '../../store/ApiSlice/widgetSlice';
import CanvasMore from '../canvas/shared/CanvasMore';
import ChartMain from '../canvas/shared/Chart-Main';
import { getMeasureList } from '../../store/ApiSlice/measureSlice';
import * as ExcelJS from 'exceljs';
import Loader from '../loader/Loader';
const ReactGridLayout = WidthProvider(RGL);

const PageMaterailList = (props) => {
    const {
        newPageID,
        handleCreateUpdate,
        handleTableRowSelect,
        setAttriId,
        setModalOpenDelete,
        setSettingLayoutData,
        finalData,
        loader,
        setLoader,
        setEditWorkSheet,
        setIsCreateWidget,
        value,
        setValue,
        formValues,
        setFormValues,
        setOpenModal,
        setOpenWorkbookName,
        setGeneralModel,
        activeTab,
        setActiveTab,
        processPageData,
        widgetGroupData,
        setWidgetGroupData,
        newActiveWidgetLoader,
        setNewActiveWidgetLoader,
        setEditWidget
    } = props
    const { canvasData, pageData, layout, timeDimensionData, filterDetail, workBookData } = useSelector((state) => state.canvas)
    const { id } = useParams();
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElWidget, setAnchorElWidget] = useState(null);
    const openMenu = Boolean(anchorElWidget);
    const [sheetValue, setSheetValue] = useState();
    const [openedMenus, setOpenedMenus] = useState({});
    const [openedWorksheet, setOpenedWorkSheet] = useState({})
    const dispatch = useDispatch();
    const canvasFindData = canvasData?.find((item) => item?._id == id)
    const findPageData = pageData
        .filter(item => item?.canvasUID?._id === canvasFindData?._id)
        .sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn));
    const [deleteWorkBook, setDeleteWorkBook] = useState(false)
    const [isInitialRender, setIsInitialRender] = useState(true);
    const [fullScreenWidget, setFullScreenWidget] = useState(null);
    const navigate = useNavigate()

    useEffect(() => {
        const currentPage = findPageData.find(item => item?._id === newPageID);
        if (layout?.length === 0 && currentPage?.widgets?.length > 0) {
            const widgetsCount = currentPage.widgets.length;
            const layouts = [];
            let defaultY = 0;
            let defaultX = 0;
            const defaultW = widgetsCount > 3 ? 3 : Math.floor(12 / widgetsCount);
            for (let index = 0; index < widgetsCount; index++) {
                if (index % 4 === 0 && index !== 0) {
                    defaultY += 5;
                    defaultX = 0;
                }

                layouts.push({
                    w: defaultW,
                    minW: 4,
                    h: 5,
                    x: defaultX,
                    y: defaultY,
                    i: index.toString(),
                    moved: false,
                    static: false,
                });

                defaultX += defaultW;
            }
            dispatch(setLayout(layouts));
        }
    }, [layout, findPageData, newPageID]);

    const handleUpdateWorkSheet = () => {
        setEditWorkSheet(true)
        setOpenModal(true)
        setOpenedWorkSheet({ id: null })
        setOpenWorkbookName({ pageId: value ? value : newPageID, workbookType: "Worksheet Group" })
    }

    useEffect(() => {
        dispatch(getWorkBookDetail())
        dispatch(getCanvasList())
        dispatch(getMeasureList())
        dispatch(getHomePageWidget())
    }, [])


    const handleCreateWidget = (item) => {
        setIsCreateWidget(true)
        setEditWorkSheet(false)
        setFormValues({})
        setOpenModal(false)
        setOpenWorkbookName({ pageId: value ? value : newPageID, workbook: item, workbookType: "Worksheet Group" })
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setActiveTab({ pageUID: value ? value : newPageID, worksheetID: "" })
    };

    const handleChangeSheet = (event, newValue) => {
        setSheetValue(newValue);
        setActiveTab({ pageUID: value ? value : newPageID, worksheetID: newValue })
        dispatch(setDropped([]))
        dispatch(setPending([]))
    };

    const handleCloseButton = (item) => {
        setAnchorEl(null);
    };

    const handleCloseWidget = () => {
        setAnchorElWidget(null)
    }

    useEffect(() => {
        if (canvasFindData) {
            dispatch(getPrimaryTimeDimension({ canvasFindData }))
            dispatch(getAllFilterAttributeDetail({ canvasFindData }))
            dispatch(getPageList({ canvasFindData }))
            dispatch(fetchDimensions({ enterpriseId: canvasFindData?.enterpriseUID?._id, modelId: canvasFindData?.modelUID?.modalUID }))

            dispatch(getFilterDetail())
        }
    }, [canvasFindData]);

    useEffect(() => {
        if (timeDimensionData && Object.keys(timeDimensionData).length > 0) {
            dispatch(getSchemaData({ timeDimensionData }));
        }
    }, [timeDimensionData]);


    const onDrop = (ev) => {
        ev.preventDefault();
        // addNewItem();
    };

    const handleClickWorkSheetMenu = (rowId) => (event) => {
        setOpenedWorkSheet((prevMenus) => ({
            ...prevMenus,
            [rowId]: event.currentTarget,
        }));
    }

    const handleCloseWorkSheetMenu = (id) => {
        setOpenedWorkSheet(prevState => ({
            ...prevState,
            [id]: null
        }));
    };

    const handleClickMenu = (rowId) => (event) => {
        setOpenedMenus((prevMenus) => ({
            ...prevMenus,
            [rowId]: event.currentTarget,
        }));
    }

    const handleCloseMenu = (id) => {
        setOpenedMenus(prevState => ({
            ...prevState,
            [id]: null
        }));
    };

    const workSheetId = activeTab?.worksheetID ? activeTab?.worksheetID : finalData?.widgetUID?.worksheet?._id || finalData?.widgetUID?._id;
    useEffect(() => {
        const datas = workBookData?.find((item) => (item?.worksheet?._id || item?._id) === workSheetId);
        if (datas) {
            setSettingLayoutData({ widgetUID: datas });
        }
    }, [activeTab?.worksheetID, workBookData, workSheetId, newPageID]);

    useEffect(() => {
        processPageData();
    }, [newPageID]);

    const getScenarioData = async () => {
        const body = {
            enterpriseName: canvasFindData?.enterpriseUID?.enterpriseName,
            modelName: canvasFindData?.modelUID?.modelName,
        };
        const result = await dispatch(getScenarios({ body }))
        if (result?.meta?.requestStatus === 'fulfilled') {
            console.log("resultresult", result);
        } else if (result?.meta?.requestStatus === 'rejected') {
            console.log("resultresult", result);
        }
    };


    const handleDeleteWorkBook = () => {
        setDeleteWorkBook(true)
    }

    const handleCloseDeleteWorkBook = () => {
        setDeleteWorkBook(false)
    }

    const handleDelete = async () => {
        const userId = localStorage.getItem("accessToken");
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(userId)?.token}`,
            },
        };
        dispatch(deleteWidget({ attriId: finalData?.widgetUID?._id, config }))
            .then((response) => {
                if (response?.meta.requestStatus === 'fulfilled') {
                    dispatch(getPageList({ canvasFindData }))
                    toast.success("Widget Deleted Successfully ");
                    dispatch(getWorkBookDetail());
                    handleCloseDeleteWorkBook()
                    setTimeout(() => {
                        dispatch(getPageList({ canvasFindData }))
                        processPageData();
                    }, [500])
                }
            })
            .catch((error) => {
                toast.error(error?.message || "An error occurred while deleting canvas.");
            });
    };


    const handleOpenWidget = (event) => {
        setAnchorElWidget(event.currentTarget)
    }
    const getLayoutFromLocalStorage = () => {
        const layoutJSON = localStorage.getItem('layout');
        return layoutJSON ? JSON.parse(layoutJSON) : [];
    };

    const saveLayoutToLocalStorage = (layout) => {
        localStorage.setItem('layout', JSON.stringify(layout));
    };
    const gridApi = useRef(null)


    useEffect(() => {
        const savedLayout = getLayoutFromLocalStorage();
        if (savedLayout.length > 0) {
            dispatch(setLayout(savedLayout));
        }
    }, [dispatch]);

    const handleLayoutChange = (newLayout) => {
        if (!isInitialRender) {
            if (JSON.stringify(newLayout) !== JSON.stringify(layout)) {
                dispatch(setLayout(newLayout));
                saveLayoutToLocalStorage(newLayout);
            }

            if (gridApi.current) {
                gridApi.current.sizeColumnsToFit();
            }
        }
        setIsInitialRender(false);
        // if (newLayoutData?.length > 0) {
        //     dispatch(setLayout(newLayoutData));
        // }
    };


    const handleNewActiveChart = async (widgetName, widgetInfo) => {

        setNewActiveWidgetLoader({
            ...newActiveWidgetLoader,
            [widgetName]: true
        })

        const updateData = await Promise.all(widgetGroupData?.map(async (widgetInfoItem) => {
            if (widgetInfoItem?.PageID === newPageID) {
                let newData = {};

                for (const [groupName, groupData] of Object.entries(widgetInfoItem?.pageTab || {})) {
                    if (widgetName === groupName) {
                        const newDataArray = await Promise.all(
                            widgetInfoItem?.pageTab[groupName]?.map(async (data) => {
                                if ((data?.widgetUID?.worksheet?._id && (data?.widgetUID?.worksheet?._id === widgetInfo?.widgetUID?.worksheet?._id)) ||
                                    (data?.widgetUID?._id && (data?.widgetUID?._id === widgetInfo?.widgetUID?._id))) {
                                    const defaultActiveWidgets = widgetInfo;
                                    const findFilterId = filterDetail?.find(filter => filter?._id === defaultActiveWidgets?.widgetUID?.filter);
                                    const widgetType = defaultActiveWidgets?.widgetUID?.widgetType === "Chart" ? defaultActiveWidgets?.widgetUID?.chart : defaultActiveWidgets?.widgetUID?.worksheet;
                                    const timeName = widgetType?.timePeriods?.periodType;
                                    let result;
                                    if (defaultActiveWidgets?.widgetUID?.widgetSubType === "Measure Data" || defaultActiveWidgets?.widgetUID?.widgetType === "Chart") {
                                        const payload = {
                                            ...(widgetType?.measures?.length > 0 && {
                                                measures: widgetType?.measures,
                                            }),
                                            timeFields: {
                                                [timeName]: widgetType?.timePeriods?.periodValues,
                                            },
                                            aggregationFields: widgetType?.attributes?.map(
                                                (attr) => attr?.attributeName
                                            ),
                                            ...(defaultActiveWidgets?.widgetUID?.scenario?.length > 0 && {
                                                scenario: defaultActiveWidgets?.widgetUID?.scenario,
                                            }),
                                            ...(defaultActiveWidgets?.widgetUID?.cycleID?.length > 0 && {
                                                cycleID: defaultActiveWidgets?.widgetUID?.cycleID,
                                            }),
                                            ...(defaultActiveWidgets?.widgetUID?.lagID?.length > 0 && {
                                                lagID: defaultActiveWidgets?.widgetUID?.lagID,
                                            }),
                                            ...(findFilterId?.attributes?.length > 0 && {
                                                additionalFilters: findFilterId.attributes.map((attr) => ({
                                                    attribute: attr?.attributeUID?.name,
                                                    operator: attr?.operator,
                                                    values: attr?.values,
                                                })),
                                            }),
                                        };

                                        result = await dispatch(createViewAggregated({ payload }));
                                        if (defaultActiveWidgets?.widgetUID?.widgetType === "Chart") {
                                            setLoader({
                                              ...loader,
                                              [groupName]: false
                                            });
                                          } else if (defaultActiveWidgets?.widgetUID?.widgetSubType === "Measure Data") {
                                            setLoader(false);
                                          }
                                    } else {
                                        const payload = {
                                            dimensionUID: defaultActiveWidgets?.widgetUID?.worksheet?.dimension,
                                            attributes: defaultActiveWidgets?.widgetUID?.worksheet?.attributes?.map((item) => item?.attributeName),
                                            ...(findFilterId?.attributes?.length > 0 && {
                                                additionalFilters: findFilterId?.attributes?.map((item) => ({
                                                    attribute: item?.attributeUID?.name,
                                                    operator: item?.operator,
                                                    values: item?.values
                                                }))
                                            }),
                                            widgetUID: defaultActiveWidgets?.widgetUID?.worksheet?._id,
                                            scenario: defaultActiveWidgets?.widgetUID?.scenario?.map((item) => item),
                                        }
                                        result = await dispatch(createDimensionFilteredmasterData({ payload }));
                                        setLoader(false);
                                    }

                                    return { ...data, getResul: result?.payload?.data };
                                } else {
                                    const { getResul, ...rest } = data;
                                    return rest;
                                }
                            })
                        );

                        newData[groupName] = newDataArray;
                    } else {
                        newData[groupName] = groupData;
                    }
                }

                return {
                    ...widgetInfoItem,
                    pageTab: newData,
                };
            } else {
                return widgetInfoItem;
            }
        }));

        setNewActiveWidgetLoader({
            ...newActiveWidgetLoader,
            [widgetName]: false
        })

        setWidgetGroupData(updateData);
    };


    const handleFullScreenToggle = (groupIndex) => {

        const currentPage = findPageData.find(item => item?._id === newPageID);
        const widgetsCount = currentPage.widgets.length;
        const defaultW = widgetsCount > 3 ? 3 : widgetsCount === 1 ? 4 : Math.floor(12 / widgetsCount);

        if (fullScreenWidget === groupIndex) {
            setFullScreenWidget(null);
            setTimeout(() => {
                const newLayout = layout?.map((l, i) =>
                    i === groupIndex ? { ...l, w: defaultW, h: 5 } : l
                );
                dispatch(setLayout(newLayout));
            }, 0);

        } else {
            setFullScreenWidget(groupIndex);
            setTimeout(() => {
                const newLayout = layout?.map((l, i) =>
                    i === groupIndex ? { ...l, w: 12, h: 6 } : l
                );
                dispatch(setLayout(newLayout));
            }, 0);
        }
    };

    const generateExcel = (datas) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        if (datas?.length > 0) {
            // Add headers
            const header = Object.keys(datas[0])?.filter(key => key !== "_id");
            worksheet.addRow(header);

            // Add rows
            datas.forEach(obj => {
                const row = header?.map(col => obj[col] !== undefined ? obj[col] : '');
                worksheet.addRow(row);
            });
        }

        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            const fileName = 'measure_data.xlsx';
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
        });
    };

    useEffect(() => {
        const updateLayoutState = () => {
            const activepageData = widgetGroupData.find((PageInfo) => PageInfo.PageID === newPageID);

            let updatedLayoutData = [...layout].filter(item => item !== null);  // Filter out null values

            if (activepageData) {
                Object.keys(activepageData?.pageTab).forEach((groupName, groupIndex) => {
                    const widgets = activepageData.pageTab[groupName];
                    const activeWidgetsData = widgets.find((widgetItem) => widgetItem.getResul);

                    // Ensure the widget has default layout properties
                    const defaultWidgetLayout = {
                        w: 3,    // Default width (adjust according to your grid settings)
                        h: 5,    // Default height
                        minW: 3, // Default minimum width
                        moved: false,
                        static: false,
                    };

                    if (activeWidgetsData) {
                        let currentX = groupIndex % 4;  // Auto-set x position (0, 1, 2, 3 for 4 items per row)
                        let currentY = Math.floor(groupIndex / 4) * 5;  // Auto-set y position, increments by 5 for new rows

                        updatedLayoutData[groupIndex] = {
                            ...defaultWidgetLayout, // Default layout properties
                            ...updatedLayoutData[groupIndex], // Preserve existing properties
                            x: updatedLayoutData[groupIndex]?.x !== undefined ? updatedLayoutData[groupIndex].x : currentX,
                            y: updatedLayoutData[groupIndex]?.y !== undefined ? updatedLayoutData[groupIndex].y : currentY,
                            widgetId: activeWidgetsData?.widgetUID?._id,
                            pageId: newPageID,
                        };
                    }
                });

                // Filter out any remaining null entries again after processing
                updatedLayoutData = updatedLayoutData.filter(item => item !== null);

                if (!isInitialRender) {
                    if (JSON.stringify(updatedLayoutData) !== JSON.stringify(layout)) {
                        dispatch(setLayout(updatedLayoutData));
                        saveLayoutToLocalStorage(updatedLayoutData);
                    }

                    if (gridApi.current) {
                        gridApi.current.sizeColumnsToFit();
                    }
                }
                setIsInitialRender(false);
            }
        };

        updateLayoutState();
    }, [widgetGroupData, layout, newPageID, findPageData]);

    const gridRef = useRef()

    const copyToClipboard = () => {
        if (!gridRef.current) return;

        const gridApi = gridRef.current.api;

        const columnDefs = gridApi.getColumnDefs();
        const headers = columnDefs.map(col => col.headerName || col.field).join('\t');

        const selectedRows = gridApi.getSelectedRows();
        const allRows = [];
        gridApi.forEachNode((node) => {
            allRows.push(node.data);
        });

        const dataToCopy = selectedRows.length > 0 ? selectedRows : allRows;

        if (dataToCopy.length === 0) {
            toast.error('No data available to copy');
            return;
        }

        const rowData = dataToCopy.map(row =>
            columnDefs.map(col => row[col.field]).join('\t')
        ).join('\n');

        const clipboardData = `${headers}\n${rowData}`;

        navigator.clipboard.writeText(clipboardData)
            .then(() => {
                toast.success('Data copied to clipboard');
            })
            .catch(err => {
                toast.error('Could not copy text:');
            });
    };

    return (
        <>
            <Box>
                <Box>
                    <Box sx={{ bgcolor: 'background.paper', borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={value ? value : newPageID}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons
                            aria-label="scrollable auto tabs example"
                        >
                            {
                                findPageData?.map((item, index) => {
                                    return (
                                        <Tab
                                            value={item?._id} onClick={() => {
                                                setActiveTab({ pageUID: "", worksheetID: "" })
                                                setValue(item?._id)
                                                handleCloseButton(item);
                                                setAnchorEl(null);
                                                setGeneralModel(false)
                                                setFormValues({})
                                                setSettingLayoutData(item?.widgets?.[0])
                                                dispatch(setDropped([]))
                                                dispatch(setPending([]))
                                            }}
                                            label={
                                                <CanvasMore
                                                    item={item}
                                                    newPageID={newPageID}
                                                    handleClickMenu={handleClickMenu}
                                                    openedMenus={openedMenus}
                                                    handleCloseMenu={handleCloseMenu}
                                                    handleCreateWidget={handleCreateWidget}
                                                    handleCreateUpdate={handleCreateUpdate}
                                                    handleTableRowSelect={handleTableRowSelect}
                                                    setModalOpenDelete={setModalOpenDelete}
                                                    setAttriId={setAttriId}
                                                    handleOpenWidget={handleOpenWidget}
                                                    getScenarioData={getScenarioData}
                                                    setDropped={setDropped}
                                                    anchorElWidget={anchorElWidget}
                                                    openMenu={openMenu}
                                                    handleCloseWidget={handleCloseWidget}
                                                    setGeneralModel={setGeneralModel}
                                                    setAnchorElWidget={setAnchorElWidget}
                                                    setOpenedMenus={setOpenedMenus}
                                                    formValues={formValues}
                                                    setFormValues={setFormValues}
                                                />
                                            }
                                        />
                                    )
                                })
                            }
                        </Tabs>
                    </Box>

                    {widgetGroupData?.length > 0 ? (

                        <TabContext value={value ? value : newPageID}>
                            <div>
                                {findPageData?.map((data, index) => {
                                    return (
                                        <>
                                            <Box>
                                                <TabPanel key={data?._id} value={data?._id} sx={{ color: 'black' }}>
                                                    <div>
                                                        <ReactGridLayout
                                                            {...props}
                                                            onDrop={onDrop}
                                                            className="layout"
                                                            layout={layout}
                                                            cols={12}
                                                            rowHeight={100}
                                                            width={1200}
                                                            onLayoutChange={handleLayoutChange}
                                                            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                                                        >
                                                            {(() => {
                                                                const activepageData = widgetGroupData.find((PageInfo) => PageInfo.PageID == data?._id)

                                                                const groupedWidgets = activepageData?.pageTab
                                                                return activepageData ? Object.keys(groupedWidgets).map((groupName, groupIndex) => {
                                                                    const widgets = groupedWidgets[groupName];
                                                                    const activeWidgetsData = widgets.find((widgetItem) => widgetItem.getResul)
                                                                    return (
                                                                        <div key={groupIndex}
                                                                            data-grid={fullScreenWidget === groupIndex ?
                                                                                { x: 0, y: 0, w: 12, h: 6 } :
                                                                                layout[groupIndex]}
                                                                            style={{
                                                                                border: "1px solid white",
                                                                                boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                                                                                marginBottom: "10px",
                                                                                zIndex: "9",
                                                                                background: "white",
                                                                                padding: "10px",
                                                                                borderRadius: "10px"
                                                                            }}>
                                                                            <Box sx={{
                                                                                overflowY: "auto", height: "100%", position: "relative", paddingRight: "6px",
                                                                                '&::-webkit-scrollbar': {
                                                                                    width: '4px',
                                                                                },
                                                                                '&::-webkit-scrollbar-track': {
                                                                                    background: 'none',
                                                                                },
                                                                                '&::-webkit-scrollbar-thumb': {
                                                                                    background: 'rgb(167, 234, 254)',
                                                                                    borderRadius: '6px',
                                                                                },
                                                                            }}>

                                                                                {groupName !== "undefined" && groupName !== "Chart" && activeWidgetsData?.widgetUID.widgetType !== "Chart" ?
                                                                                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", zIndex: "9" }}>
                                                                                        <Box sx={{ margin: "0px 12px" }}>
                                                                                            <div style={{ fontSize: "12px", textTransform: "none", fontFamily: "'Poppins', sans-serif" }}>
                                                                                                {groupName !== "undefined" && groupName !== "Chart" && activeWidgetsData?.widgetUID.widgetType !== "Chart" ? groupName : ""}
                                                                                            </div>
                                                                                        </Box>
                                                                                        <Box>
                                                                                            <Tooltip title={fullScreenWidget !== groupIndex ? "Full Screen" : "Shrink To Original"}>
                                                                                                <IconButton onClick={() => handleFullScreenToggle(groupIndex)} sx={{ cursor: "pointer" }}>
                                                                                                    {fullScreenWidget === groupIndex ? <MdFullscreenExit /> : <MdFullscreen />}
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                        </Box>
                                                                                    </Box>
                                                                                    :
                                                                                    <Box sx={{ display: "flex", justifyContent: "flex-end", zIndex: "9" }}>
                                                                                        <IconButton
                                                                                            onClick={(e) => {
                                                                                                e.stopPropagation();
                                                                                                handleClickWorkSheetMenu(activeWidgetsData?.widgetUID?.worksheet?._id || activeWidgetsData?.widgetUID?._id)(e);
                                                                                            }}
                                                                                            size="small"
                                                                                            sx={{
                                                                                                color: 'black',
                                                                                            }}
                                                                                        >
                                                                                            <MdMoreVert
                                                                                                cursor="pointer"
                                                                                                style={{ zIndex: "9" }}
                                                                                            />
                                                                                        </IconButton>
                                                                                        <Menu
                                                                                            anchorEl={openedWorksheet[activeWidgetsData?.widgetUID?.worksheet?._id || activeWidgetsData?.widgetUID?._id]}
                                                                                            open={Boolean(openedWorksheet[activeWidgetsData?.widgetUID?.worksheet?._id || activeWidgetsData?.widgetUID?._id])}
                                                                                            onClose={() => handleCloseWorkSheetMenu(activeWidgetsData?.widgetUID?.worksheet?._id || activeWidgetsData?.widgetUID?._id)}
                                                                                        >
                                                                                            <MenuItem
                                                                                                onClick={() => {
                                                                                                    handleUpdateWorkSheet();
                                                                                                    setGeneralModel(true);
                                                                                                    getScenarioData();
                                                                                                    setEditWidget(activeWidgetsData)
                                                                                                }}
                                                                                                sx={{ fontSize: "12px",fontFamily: "'Poppins', sans-serif" }}
                                                                                            >
                                                                                                Edit {activeWidgetsData?.widgetUID?.widgetType}
                                                                                            </MenuItem>
                                                                                            <MenuItem
                                                                                                onClick={() => {
                                                                                                    handleDeleteWorkBook();
                                                                                                    setSettingLayoutData(activeWidgetsData)
                                                                                                    handleCloseWorkSheetMenu(activeWidgetsData?.widgetUID?.worksheet?._id || activeWidgetsData?.widgetUID?._id)
                                                                                                }}
                                                                                                sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                                                                                            >
                                                                                                Delete {activeWidgetsData?.widgetUID?.widgetType}
                                                                                            </MenuItem>
                                                                                            <MenuItem
                                                                                                onClick={() => {
                                                                                                    handleCloseWorkSheetMenu(activeWidgetsData?.widgetUID?.worksheet?._id || activeWidgetsData?.widgetUID?._id)
                                                                                                    generateExcel(activeWidgetsData?.getResul)
                                                                                                }}
                                                                                                sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                                                                                            >
                                                                                                Download Data
                                                                                            </MenuItem>
                                                                                            <MenuItem
                                                                                                onClick={() => {
                                                                                                    handleFullScreenToggle(groupIndex)
                                                                                                    handleCloseWorkSheetMenu(activeWidgetsData?.widgetUID?.worksheet?._id || activeWidgetsData?.widgetUID?._id)
                                                                                                }}
                                                                                                sx={{ cursor: "pointer", fontSize: "12px" }}
                                                                                            >
                                                                                                {fullScreenWidget === groupIndex ? "Shrink To Original" : "Full Screen"}
                                                                                            </MenuItem>
                                                                                            <MenuItem
                                                                                                onClick={() => {
                                                                                                    dispatch(createHomeWidget(activeWidgetsData?.widgetUID?.worksheet?._id || activeWidgetsData?.widgetUID?._id))
                                                                                                    handleCloseWorkSheetMenu(activeWidgetsData?.widgetUID?.worksheet?._id || activeWidgetsData?.widgetUID?._id)
                                                                                                    dispatch(getHomePageWidget())
                                                                                                    navigate(`/widget/${activeWidgetsData?.widgetUID?._id}`);
                                                                                                }}
                                                                                                sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                                                                                            >
                                                                                                Add To Home Page
                                                                                            </MenuItem>
                                                                                        </Menu>
                                                                                    </Box>
                                                                                }
                                                                                <Box sx={{
                                                                                    backgroundColor: activeWidgetsData?.widgetUID?.widgetType === "Worksheet" && "white",
                                                                                    color: activeWidgetsData?.widgetUID?.widgetType === "Worksheet" && "black"
                                                                                }}>
                                                                                    <div key={groupIndex}>
                                                                                        {activeWidgetsData?.widgetUID?.widgetType === "Worksheet" && (
                                                                                            <Tabs
                                                                                                value={activeWidgetsData?.widgetUID?.worksheet?._id || activeWidgetsData?.widgetUID?._id}
                                                                                                onChange={handleChangeSheet}
                                                                                                variant="scrollable"
                                                                                                scrollButtons="auto"
                                                                                                aria-label="scrollable auto tabs example"
                                                                                                sx={{
                                                                                                    "& .MuiTabs-indicator": {
                                                                                                        backgroundColor: 'transparent',
                                                                                                    },
                                                                                                    borderBottom: activeWidgetsData?.widgetUID.widgetType === "Worksheet" ? "2px solid" : "",
                                                                                                    padding: `${groupName !== "undefined" && groupName !== "Chart" && activeWidgetsData?.widgetUID.widgetType !== "Chart" ? "10px 10px" : ""}`,
                                                                                                }}
                                                                                            >
                                                                                                {widgets?.map((data, i) => {
                                                                                                    const isSelected = (data?.widgetUID?.worksheet?._id || data?.widgetUID?._id)?.includes(activeWidgetsData?.widgetUID?.worksheet?._id || activeWidgetsData?.widgetUID?._id);

                                                                                                    return (
                                                                                                        <Tab
                                                                                                            key={data?.widgetUID?.worksheet?._id || data?.widgetUID?._id}
                                                                                                            onClick={(e) => {

                                                                                                                e.preventDefault();
                                                                                                                handleNewActiveChart(groupName, data)
                                                                                                            }}

                                                                                                            label={

                                                                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center", textTransform: "none", padding: "0px 8px", minHeight: "40px" }}>
                                                                                                                    <div className='widget-name' style={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}>
                                                                                                                        {data?.widgetUID?.widgetName}
                                                                                                                    </div>
                                                                                                                    <IconButton
                                                                                                                        onClick={(e) => {
                                                                                                                            e.stopPropagation();
                                                                                                                            handleClickWorkSheetMenu(data?.widgetUID?.worksheet?._id || data?.widgetUID?._id)(e);
                                                                                                                        }}
                                                                                                                        size="small"
                                                                                                                        sx={{
                                                                                                                            color: isSelected ? 'black' : 'black',
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <MdMoreVert
                                                                                                                            cursor="pointer"
                                                                                                                            style={{ zIndex: "9" }}
                                                                                                                        />
                                                                                                                    </IconButton>
                                                                                                                    <Menu
                                                                                                                        anchorEl={openedWorksheet[data?.widgetUID?.worksheet?._id || data?.widgetUID?._id]}
                                                                                                                        open={Boolean(openedWorksheet[data?.widgetUID?.worksheet?._id || data?.widgetUID?._id])}
                                                                                                                        onClose={() => handleCloseWorkSheetMenu(data?.widgetUID?.worksheet?._id || data?.widgetUID?._id)}
                                                                                                                    >
                                                                                                                        <MenuItem
                                                                                                                            onClick={() => {
                                                                                                                                handleUpdateWorkSheet();
                                                                                                                                setGeneralModel(true);
                                                                                                                                getScenarioData();
                                                                                                                                setEditWidget(data)
                                                                                                                            }}
                                                                                                                            sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                                                                                                                        >
                                                                                                                            Edit {activeWidgetsData?.widgetUID?.widgetType}
                                                                                                                        </MenuItem>
                                                                                                                        <MenuItem
                                                                                                                            onClick={() => {
                                                                                                                                handleDeleteWorkBook();
                                                                                                                                setSettingLayoutData(data)
                                                                                                                                handleCloseWorkSheetMenu(data?.widgetUID?.worksheet?._id || data?.widgetUID?._id)
                                                                                                                            }}
                                                                                                                            sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                                                                                                                        >
                                                                                                                            Delete {activeWidgetsData?.widgetUID?.widgetType}
                                                                                                                        </MenuItem>

                                                                                                                        <MenuItem
                                                                                                                            onClick={() => {
                                                                                                                                copyToClipboard()
                                                                                                                                handleCloseWorkSheetMenu(data?.widgetUID?.worksheet?._id || data?.widgetUID?._id)
                                                                                                                            }}
                                                                                                                            sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                                                                                                                        >
                                                                                                                            Copy Data
                                                                                                                        </MenuItem>
                                                                                                                        <MenuItem
                                                                                                                            onClick={() => {
                                                                                                                                handleCloseWorkSheetMenu(data?.widgetUID?.worksheet?._id || data?.widgetUID?._id)
                                                                                                                                generateExcel(activeWidgetsData?.getResul)
                                                                                                                            }}
                                                                                                                            sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                                                                                                                        >
                                                                                                                            Download Data
                                                                                                                        </MenuItem>
                                                                                                                        <MenuItem
                                                                                                                            onClick={() => {
                                                                                                                                dispatch(createHomeWidget(activeWidgetsData?.widgetUID?._id))
                                                                                                                                handleCloseWorkSheetMenu(activeWidgetsData?.widgetUID?.worksheet?._id || activeWidgetsData?.widgetUID?._id)
                                                                                                                                dispatch(getHomePageWidget())
                                                                                                                                navigate(`/widget/${activeWidgetsData?.widgetUID?._id}`);
                                                                                                                            }}
                                                                                                                            sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                                                                                                                        >
                                                                                                                            Add To Home Page
                                                                                                                        </MenuItem>
                                                                                                                    </Menu>
                                                                                                                </div>
                                                                                                            }
                                                                                                            value={(data?.widgetUID?.worksheet?._id) || data?.widgetUID?._id}
                                                                                                            sx={
                                                                                                                data?.widgetUID?.widgetType === "Worksheet"
                                                                                                                    ? {
                                                                                                                        '&.Mui-selected': {
                                                                                                                            backgroundColor: 'lightblue',
                                                                                                                            color: 'black',
                                                                                                                            borderRadius: '10px',
                                                                                                                            padding: "0px 8px"
                                                                                                                        },
                                                                                                                        '&:not(.Mui-selected)': {
                                                                                                                            backgroundColor: 'inherit',
                                                                                                                            color: 'black',
                                                                                                                            padding: "0px 8px"
                                                                                                                        },
                                                                                                                    }
                                                                                                                    : {}
                                                                                                            }

                                                                                                        />
                                                                                                    )
                                                                                                })}
                                                                                            </Tabs>
                                                                                        )}
                                                                                        <TabContext
                                                                                            key={activeWidgetsData?.widgetUID?.worksheet?._id || activeWidgetsData?.widgetUID?._id}
                                                                                            value={activeWidgetsData?.widgetUID?.worksheet?._id || activeWidgetsData?.widgetUID?._id}
                                                                                        >
                                                                                            {widgets?.map((widgetData, i) => {
                                                                                                return (
                                                                                                    <TabPanel
                                                                                                        key={widgetData?.widgetUID?.worksheet?._id || widgetData?.widgetUID?._id}
                                                                                                        value={widgetData?.widgetUID?.worksheet?._id || widgetData?.widgetUID?._id}
                                                                                                        sx={{ color: 'black', textAlign: "start" }}
                                                                                                    >
                                                                                                        {activeWidgetsData?.widgetUID?.widgetType === "Worksheet" ? (
                                                                                                            <MyGridComponent
                                                                                                                loader={newActiveWidgetLoader?.[groupName]}
                                                                                                                setLoader={setLoader}
                                                                                                                finalData={activeWidgetsData}
                                                                                                                aggregatedViewData={activeWidgetsData?.getResul}
                                                                                                                gridRef={gridRef}
                                                                                                            />
                                                                                                        ) : (
                                                                                                            <ChartMain
                                                                                                                loader={newActiveWidgetLoader?.[groupName]}
                                                                                                                setLoader={setNewActiveWidgetLoader}
                                                                                                                groupName={groupName}
                                                                                                                finalData={activeWidgetsData}
                                                                                                                widgetData={widgetData}
                                                                                                                aggregatedViewData={activeWidgetsData?.getResul}
                                                                                                            />
                                                                                                        )}
                                                                                                    </TabPanel>
                                                                                                )
                                                                                            })}
                                                                                        </TabContext>
                                                                                    </div>
                                                                                </Box>
                                                                            </Box>
                                                                        </div>
                                                                    );
                                                                }) : null
                                                            })()}
                                                        </ReactGridLayout>
                                                    </div>
                                                </TabPanel>
                                            </Box >
                                        </>
                                    )
                                })}
                            </div>
                        </TabContext >
                    ) : (
                        <>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "115px"
                            }}>
                                <Loader />
                            </div>
                        </>
                    )}


                </Box>
            </Box>

            {deleteWorkBook && (
                <Dialog open={deleteWorkBook} onClose={handleCloseDeleteWorkBook}>
                    <DialogTitle>Delete Widget</DialogTitle>
                    <DialogContent style={{ width: "500px", margin: "2px" }}>
                        <Typography variant="h6" component="h2">
                            This is irreversible.Are you sure?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDeleteWorkBook}>Cancel</Button>
                        <Button
                            onClick={() => {
                                handleDelete();
                            }}
                            color="primary"
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    )
}

export default PageMaterailList